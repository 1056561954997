let url_base=`${process.env.REACT_APP_API_URL}`;
var host = window.location.host;
if(host!="http://54.147.228.241"){
 url_base=`${process.env.REACT_APP_API_URL_1}`;
}


let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export async function getCollectionDetails(data) {
    const response = await fetch(url_base+`api/product-list-by-collection`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    })
    return await response.json();
}

export async function getSmartContactId(data) {
    const response = await fetch(url_base+`api/get-smart-contact`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    })
    return await response.json();
}

export async function getAllCollectionList() {
    const response = await fetch(url_base+`api/all-collection-list`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({})
    })
    return await response.json();
}

export async function updateBuyingStatus(data) {
    const response = await fetch(url_base+`api/update-buying-status`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    })
    return await response.json();
}

export async function claimingRequest(data) {
    const response = await fetch(url_base+`api/add-claiming-request`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    })
    return await response.json();
}

export async function getMetalList(data) {

    const response = await fetch(url_base+`api/metal-list`, {
        method: 'GET',
        headers: headers
      
    })
    return await response.json();
  }

