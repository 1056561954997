import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import Brand from './pages/brand/Brand';
import Collection from './pages/collection/Collection';
import Notfound from './pages/notFound/Notfound';
import Product from './pages/product/Product';
import ConnectWallet from './pages/wallet/ConnectWallet';
import Home from './pages/home/Home';
import ShippingPayment from "./pages/Payment/ShippingPayment";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import AllSearchResults from './pages/search/AllSearchResults';

function App() {
  const [showCta, setShowCta] = useState(true);
  const changeShowCta = (response) => {
    setShowCta(response)
  }
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="*" exact element={<Notfound heading="404 Not Found" />}></Route>
          <Route path="/brand/:brandid" exact element={<Brand onChangeCta={changeShowCta} />}></Route>
          <Route path="/collection/:collectionid" exact element={<Collection onChangeCta={changeShowCta} />}></Route>
          <Route path="/product/:productid" exact element={<Product onChangeCta={changeShowCta} />}></Route>
          <Route path='/all-search-results/:searchkey' exact element={<AllSearchResults onChangeCta={changeShowCta} />}></Route>
          <Route path="/connect-wallet" exact element={<ConnectWallet />}></Route>
          <Route path="/home" exact element={<Home />} ></Route>
          <Route exact path="/shipping-payment/:id" element={<ShippingPayment/>} />
          <Route exact path="/payment-success" element={<PaymentSuccess/>} />
          <Route path="/" exact element={<Home />} ></Route>
        </Routes>
        <Footer cta={showCta} />
      </Router>
    </div>
  );
}

export default App;
