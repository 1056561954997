import React, { Component } from 'react';
import {getRequestDetails,getPaymentAccessToken,sendPayment,updateShippingPaymentStatus}  from '../../services/PaymentService';
class ShippingPayment extends Component {


    constructor(props) {
        super(props)
        const pathArray = window.location.pathname.split('/');
        const request_id =pathArray[2];
        this.state = {
            id:0,
            brand_id:0,
            request_id:request_id,
            loader:false,
            claimDetails:'',
            accessToken:'',
            expYear:'',
            expMonth:'',
            region:'',
            postalCode:'',
            streetAddress:'',
            country:'',
            city:'',
            cardnumber:'',
            cardcvc:'',
            cardname:'',
            isError:{
                responseErr:''
            }
        }
        this.getRequestDetails(request_id);
        this.getPaymentAccessToken();
    }
    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    };

    getRequestDetails = (id) => {
        this.setState({loader: true});
        getRequestDetails(id).then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                let isError = {...this.state.isError}
                isError.responseErr = response.error;
                this.setState({isError});
            }else{
                this.setState({
                    claimDetails: response.data,
                });
               
                if(this.state.claimDetails.shipping_payment_status==1){
                    window.location.href='./payment-success';
                }
                
                
            }
                 })
    };

    getPaymentAccessToken = () => {
        this.setState({loader: true});
        getPaymentAccessToken().then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                let isError = {...this.state.isError}
                isError.responseErr = response.error;
                this.setState({isError});
            }else{
                this.setState({
                    accessToken: response.accessToken,
                });
               
                
                
            }
                 })
    };

    updateShippingPaymentStatus = (id) => {
        this.setState({loader: true});
        updateShippingPaymentStatus(id).then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                
            }else{
                
                window.location.href='./../payment-success';
     
            }
                 })
    };

   

    submitPayment=()=>{
     let data={
            "amount": this.state.claimDetails.shipping_fee,
            "card": {
               "expYear": this.state.expYear,
               "expMonth": this.state.expMonth,
               "address": {
                     "region": this.state.region,
                     "postalCode": this.state.postalCode,
                     "streetAddress": this.state.streetAddress,
                     "country": this.state.country,
                     "city": this.state.city,
               },
               "name": this.state.cardname,
               "cvc":this.state.cardcvc,
               "number": this.state.cardnumber,
            },
            "currency": "USD",
            "context": {
               "mobile": "false",
               "isEcommerce": "true"
            }
         };

         this.setState({loader: true});
         sendPayment(data,this.state.accessToken).then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
             alert("Payment not done.Please try again");   
            }else{
                console.log(response.data);
               this.updateShippingPaymentStatus(this.state.request_id);
                
            }
                 })
    }
    render () {
        const {loader} = this.state;
        return (
<div className="main-content main-content-override bg-dark">
            <div className="page-content page-content-notop">
           
            <div className="container-fluid">
          
           
           

            <div className="row text-center">
            <div className="col-lg-6 margin-auto color-black">
            <div className="card">
                <div className="card-body margin-10">
                <div className="row">
                                     <div className="col-md-12">
                                         <div className="form-group">
                                             <label for="couponCode">Payment Amount : <b> {this.state.claimDetails.shipping_fee} USD</b></label>
                                          
                                         </div>
                                     </div>
                                 </div>
                    <div className="row">
            <form role="form" id="payment-form" >
                                 <div className="row">
                                     <div className="col-md-12">
                                         <div className="form-group">
                                             <label for="cardNumber">CARD NUMBER</label>
                                             <div className="input-group">
                                                 <input onChange={ this.formValChange } 
                                                         type="tel"
                                                         className="form-control"
                                                         name="cardnumber"
                                                         placeholder="Valid Card Number"
                                                         autocomplete="cc-number"
                                                         required autofocus
                                                         id = "cardNumber"
                                                 />
                                                 <span className="input-group-addon"><i className="fa fa-credit-card"></i></span>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="row">
                                 <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="expMonth"><span className="hidden-xs">EXP Month</span></label>
                                             <input onChange={ this.formValChange } 
                                                     type="tel"
                                                     className="form-control"
                                                     name="expMonth"
                                                     placeholder="MM"
                                                     autocomplete="cc-exp"
                                                     required autofocus
                                                     id="expMonth"
                                             />
                                         </div>
                                     </div>
                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="expYear"><span className="hidden-xs">EXP Year</span></label>
                                             <input onChange={ this.formValChange } 
                                                     type="tel"
                                                     className="form-control"
                                                     name="expYear"
                                                     placeholder="YYYY"
                                                     autocomplete="cc-exp"
                                                     required autofocus
                                                     id="expYear"
                                             />
                                         </div>
                                     </div>
                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="cardCVC">CVC</label>
                                             <input onChange={ this.formValChange } 
                                                     type="tel"
                                                     className="form-control"
                                                     name="cardcvc"
                                                     placeholder="CVC"
                                                     autocomplete="cc-csc"
                                                     required
                                                     id="cvc"
                                             />
                                         </div>
                                     </div>
                                 </div>

                                 <div className="row">
                                     <div className="col-md-12">
                                         <div className="form-group">
                                             <label for="couponCode">NAME ON CARD</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="cardname" id="name"/>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div className="row">
                                     <div className="col-md-6">
                                         <div>
                                             <label for="couponCode">COUNTRY</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="country" value="US" readonly/>
                                         </div>
                                     </div>

                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="couponCode">CITY</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="city" id="city"/>
                                         </div>
                                     </div>
                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="couponCode">POSTAL CODE</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="postalcode" id="postalCode"/>
                                         </div>
                                     </div>
                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="couponCode">Region</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="region" id="postalCode"/>
                                         </div>
                                     </div>
                                     <div className="col-md-6">
                                         <div className="form-group">
                                             <label for="couponCode">Street Address</label>
                                             <input onChange={ this.formValChange }  type="text" className="form-control" name="streetAddress" id="postalCode"/>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 
                                 <br/>
                                 <div className="row">
                                     <div className="col-md-12">
                                         <a  onClick={() => this.submitPayment()} className="btn btn-success btn-lg btn-block" id="createtoken" type="submit">Submit</a>
                                     </div>
                                 </div>
                             </form>

                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
        );
    }
}

export default ShippingPayment;