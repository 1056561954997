import React from 'react'
const Footer = (props) => {
    return (
        <>

            {props.cta && (
                <section className="brand_section">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="brand_section_inner">
                                    <div className="brand_section_left">
                                        <img src="/assets/img/test.png" alt="" />
                                    </div>
                                    <div className="brand_section_right">
                                        <p className="brand_heading_small">Full Ownership</p>
                                        <p className="brand_heading">Own an ALQBAR, <br />Own the Metals.</p>
                                        <p className="brand_txt">ALQBAR's mission is to democratize access  to the metals-specificically-PGMs-that Power the world.</p>
                                        <div className="brand_cta_btn_ar">
                                            <button className="btn btn-light btn-lg brand_cta_btn">Get Started</button>
                                            <button className="btn btn-light btn-lg brand_cta_btn ml-2">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="footer_link">
                                <li>&copy; Trendsy</li>
                                <li><a href="#">Community Guidelines</a></li>
                                <li><a href="#">Terms</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
