import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { getQueryData } from '../../helper/Helper'
import { getAllCollectionList } from '../../services/CollectionService'
import { Link, useLocation } from 'react-router-dom'

const AllSearchResults = (props) => {
    const location = useLocation();
    const [searchKey, setsearchKey] = useState('');
    const [searchResultsByCollection, setSearchResultsByCollection] = useState([]);
    const [searchResultsByProduct, setSearchResultsByProduct] = useState([]);
    const [loading, setLoading] = useState(true);

    document.title = 'Search results | Trendsy';
    useEffect(() => {
        props.onChangeCta(false);
        window.scrollTo(0, 0);
        var searchListByCollection = [];
        var searchListByProduct = [];
        /* fetching all colletion list from DB */
        getAllCollectionList().then((response) => {
            setsearchKey(getQueryData());
            setLoading(true);
            if (response.code == 200) {
                var searchResultsList = [];
                response.data.collection_list.forEach((element, index) => {
                    if (element.collection_name.toLowerCase().match(getQueryData().toLowerCase())) {
                        searchResultsList.push(element);
                    }
                    if (getQueryData() == 'allCollectionExplore') {
                        searchResultsList.push(element);
                    }
                    if (element.product_list.product_list.length > 0) {
                        element.product_list.product_list.forEach(products => {
                            if (products.product_name.toLowerCase().match(getQueryData().toLowerCase())) {
                                if (products.serial.length > 0) {
                                    products.serial.forEach(serial => {
                                        serial['product_name'] = products.product_name;
                                        searchResultsList.push(serial);
                                    });
                                }
                            }
                            if (getQueryData() == 'allNFTExplore') {
                                if (products.serial.length > 0) {
                                    products.serial.forEach(serial => {
                                        serial['product_name'] = products.product_name;
                                        searchResultsList.push(serial);
                                    });
                                }
                            }
                        });
                    }
                })
                if (searchResultsList.length > 0) {
                    searchResultsList.forEach(element => {
                        if ('collection_name' in element) {
                            if (element.collection_name.toLowerCase().match(getQueryData().toLowerCase())) {
                                searchListByCollection.push(element);
                            }
                            if (getQueryData() == 'allCollectionExplore') {
                                searchListByCollection.push(element);
                            }
                        }
                        if ('product_name' in element) {
                            if (element.product_name.toLowerCase().match(getQueryData().toLowerCase())) {
                                searchListByProduct.push(element);
                            }
                            if (getQueryData() == 'allNFTExplore') {
                                if (searchListByProduct.length < 50)
                                    searchListByProduct.push(element);
                            }
                        }
                    });
                    setSearchResultsByCollection(searchListByCollection)
                    setSearchResultsByProduct(searchListByProduct)
                    setLoading(false);
                }
            }
        })
    }, [location])

    /* tab changer */
    const tabChanger = (event) => {
        let element = event.currentTarget;
        let tab_id = element.getAttribute('data-tab');
        $('ul.product_tab li a').removeClass('current');
        $('.prdBlock').removeClass('current');
        $(element).addClass('current');
        $("#" + tab_id).addClass('current');
    }
    return (
        <>
            <section className="product_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                        {searchKey != 'allCollectionExplore' && searchKey != 'allNFTExplore' && (
                            <div className="product_nav_section">
                                <ul className="product_tab">
                                   
                                        <>
                                            <li><a href="javascript:void(0)" onClick={tabChanger} id="nft_tab" data-tab="prdTab-1" className="current">Nfts</a></li>
                                            <li><a href="javascript:void(0)" onClick={tabChanger} id="collection_tab" data-tab="prdTab-2" className="">Collections</a></li>
                                        </>
                                  
                                </ul>
                            </div>
                              )}
                            <div className="product_conty_section">
                                {searchKey != 'allCollectionExplore' && searchKey != 'allNFTExplore' && (
                                    <>
                                        <div className={'prdBlock current'} id="prdTab-1">
                                            <div className="product_display_section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="product_display_thumb_ar">
                                                            <div className="row">
                                                                {!loading && searchResultsByProduct.length > 0 && (
                                                                    <>
                                                                        {searchResultsByProduct.map((item, index) => {
                                                                            return (
                                                                                <div className="col-md-2 product_display_blk" key={'productListSection' + index}>
                                                                                    <div className="product_display_thumb">
                                                                                        <div className="product_display_image_thumb">
                                                                                            <img src={item.product_image} alt="product image" />
                                                                                        </div>
                                                                                        <div className="product_display_details">
                                                                                            <div className="product_display_details_left">
                                                                                                <span>Product Name</span>
                                                                                                <span>{item.product_name}</span>
                                                                                            </div>
                                                                                            <div className="product_display_details_righr">
                                                                                                <span>Price</span>
                                                                                                <span>{item.eth_price.toFixed(6)} (ETH)</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="product_display_button">
                                                                                            <Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">View Quark</button></Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'prdBlock'} id="prdTab-2">
                                            <div className="activity_block">
                                                <div className="row">

                                                    {!loading && searchResultsByCollection.length > 0 && searchResultsByCollection.map((collection, index) => {
                                                        let buying_list = [];
                                                        if (collection.product_list.product_list.length > 0) {
                                                            collection.product_list.product_list.forEach(product => {
                                                                product.serial.forEach(function (serial, index) {
                                                                    buying_list.push(serial);
                                                                });
                                                            });
                                                        }
                                                        return (
                                                            <div className="col-md-4">
                                                                <div className="activity_block_row" key={'collectionListSection' + index}>
                                                                    <div className="activity_block_image_thumb activity_block_image_thumb_mod">
                                                                        <a href="#">
                                                                            <img src={collection.collection_image} alt="" />
                                                                        </a>
                                                                        <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                                    </div>
                                                                    <div className="activity_block_conty">
                                                                        <p className="activity_heading"><Link to={'/collection/' + btoa(collection.id)}>{collection.collection_name}</Link></p>
                                                                        <p className="activity_sub">
                                                                            <span className="activity_sub_1">listed by</span>
                                                                            <span className="activity_identification">
                                                                                <img src={collection.product_list.brand.brand_image} alt="" />
                                                                            </span>
                                                                            <span className="activity_sub_3"><Link to={'/brand/' + btoa(collection.product_list.brand.id)}>{collection.product_list.brand.brand_name}</Link></span>
                                                                            {buying_list.length > 0 && (
                                                                                <span className="activity_sub_4">for</span>
                                                                            )}
                                                                            <span className="activity_sub_5">{buying_list.length > 0 ? (buying_list.length * buying_list[0].eth_price).toFixed(6) + ' ETH' : ''}</span>
                                                                        </p>
                                                                        <div className="product_display_button">
                                                                            <Link to={'/collection/' + btoa(collection.id)}><button className="common_full_button">View Collection</button></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {searchKey == 'allCollectionExplore' && (
                                    <div className={'prdBlock current'} id="prdTab-2">
                                        <div className="activity_block">
                                            <div className="row">

                                                {!loading && searchResultsByCollection.length > 0 && searchResultsByCollection.map((collection, index) => {
                                                    let buying_list = [];
                                                    if (collection.product_list.product_list.length > 0) {
                                                        collection.product_list.product_list.forEach(product => {
                                                            product.serial.forEach(function (serial, index) {
                                                                buying_list.push(serial);
                                                            });
                                                        });
                                                    }
                                                    return (
                                                        <div className="col-md-4">
                                                            <div className="activity_block_row" key={'collectionListSection' + index}>
                                                                <div className="activity_block_image_thumb activity_block_image_thumb_mod">
                                                                    <a href="#">
                                                                        <img src={collection.collection_image} alt="" />
                                                                    </a>
                                                                    <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                                </div>
                                                                <div className="activity_block_conty">
                                                                    <p className="activity_heading"><Link to={'/collection/' + btoa(collection.id)}>{collection.collection_name}</Link></p>
                                                                    <p className="activity_sub">
                                                                        <span className="activity_sub_1">listed by</span>
                                                                        <span className="activity_identification">
                                                                            <img src={collection.product_list.brand.brand_image} alt="" />
                                                                        </span>
                                                                        <span className="activity_sub_3"><Link to={'/brand/' + btoa(collection.product_list.brand.id)}>{collection.product_list.brand.brand_name}</Link></span>
                                                                        {buying_list.length > 0 && (
                                                                            <span className="activity_sub_4">for</span>
                                                                        )}
                                                                        <span className="activity_sub_5">{buying_list.length > 0 ? (buying_list.length * buying_list[0].eth_price).toFixed(6) + ' ETH' : ''}</span>
                                                                    </p>
                                                                    <div className="product_display_button">
                                                                        <Link to={'/collection/' + btoa(collection.id)}><button className="common_full_button">View Collection</button></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>

                                )}
                                {searchKey == 'allNFTExplore' && (
                                    <div className={'prdBlock current'} id="prdTab-1">
                                        <div className="product_display_section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="product_display_thumb_ar">
                                                        <div className="row">
                                                            {!loading && searchResultsByProduct.length > 0 && (
                                                                <>
                                                                    {searchResultsByProduct.map((item, index) => {
                                                                        return (
                                                                            <div className="col-md-2 product_display_blk" key={'productListSection' + index}>
                                                                                <div className="product_display_thumb">
                                                                                    <div className="product_display_image_thumb">
                                                                                        <img src={item.product_image} alt="product image" />
                                                                                    </div>
                                                                                    <div className="product_display_details">
                                                                                        <div className="product_display_details_left">
                                                                                            <span>Product Name</span>
                                                                                            <span>{item.product_name}</span>
                                                                                        </div>
                                                                                        <div className="product_display_details_righr">
                                                                                            <span>Price</span>
                                                                                           
                                                                                              
                                                <span>{parseFloat(item.eth_price).toFixed(4)}(ETH) ({parseFloat(item.price).toFixed(2)} USD)</span>
                                             
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="product_display_button">
                                                                                        <Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">View Quark</button></Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AllSearchResults
