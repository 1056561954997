import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import BannerAndLogo from '../../components/BannerAndLogo';
import UserDetails from '../../components/UserDetails';
import { encrypt, getQueryData } from '../../helper/Helper';
import { getCollectionList, getSmartContactId } from '../../services/CollectionService';
import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

const NotFound = (props) => {
    
    document.title = "Trendsy Page Not Found";

    useEffect(() => {
       
    }, []);
    return (
        <>
            
            {/* product section */}
            <section className="details_section">
                <div className="container-fluid">
                    <div className="row" style={{paddingBottom:'50px'}}>
                    <div className="col-md-2">
                        </div>
                        <div className="col-md-8">
                            <div className="product_details_ar">
                            <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">
                    Sorry, an error has occured, Requested page not found!
                </div>
                <div class="error-actions">
                    <a href="/home" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Take Me Home </a>
                </div>
            </div>
        </div>
    </div>
                </div>
                </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default NotFound
