import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom';
import { getCollectionDetails, getMetalList, updateBuyingStatus, getSmartContactId } from '../../services/CollectionService';
import { getProductRelatedData, updateMintStatus, createJson } from '../../services/ProductService';
import { getQueryData } from '../../helper/Helper';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery'
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import TRENDSY from '../../contracts/Trendsy.json';
import { useWeb3React } from "@web3-react/core";
import WalletConnectProvider from "@walletconnect/web3-provider";

import {
    TRENDSYMARKET_ADDRESS, PRIVATE_KEY, RPCPROVIDER
} from '../../config.js';
import swal from 'sweetalert';
// import { useWeb3React } from '@web3-react/core';

const Product = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [brand, setBrand] = useState('');
    const [collection, setcollection] = useState('');
    const [product, setProduct] = useState('');
    const [smartContact, setSmartContact] = useState([]);
    const [productListOfCollection, setProductListOfCollection] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [metalList, setMetalList] = useState([]);
    const location = useLocation();
    const { activate, account, connector } = useWeb3React();
    document.title = "Product Details"
    useEffect(() => {
        setIsLoading(true);
        let product_id_base_64 = btoa(getQueryData());
        getProductRelatedData(product_id_base_64).then((response) => {
            if (response.code == 200) {
                setBrand(response.data.brand);
                setcollection(response.data.collection);
                setProduct(response.data.product);
                let data = {
                    collection_id: response.data.collection.id,
                    minting_status: 1
                }

                /* getting smart contact */
                let data2 = {
                    brand_id: response.data.brand.id,
                    collection_id: response.data.collection.id,
                }

                /* smart contact id fetching*/
                getSmartContactId(data2).then((response) => {
                    if (response.code == 200) {
                        setSmartContact(response.data.contract_id)
                    }
                })

                getCollectionDetails(data).then((response) => {
                    if (response.code == 200) {
                        setProductListOfCollection(response.data.product_list);
                        setIsLoading(false);
                    }
                })
            }
        })

        /* get all metals */
        getMetalList({}).then((response) => {
            console.log(response);
            setMetalList(response.data.trndMetalPrice)
        })

        props.onChangeCta(false)
        window.scrollTo(0, 0);
    }, [location])

    /* chnaging product properties tab */
    const changeTab = (event) => {
        let element = event.currentTarget;
        let tabid = element.getAttribute('data-tab');
        document.querySelectorAll('ul.product_details_conty_nav_list li a').forEach((element) => {
            element.classList.remove('current');
        })
        document.querySelectorAll('.product_details_conty_desc_inner_block').forEach((element) => {
            element.classList.remove('current')
        })
        element.classList.add('current');
        document.querySelector("#" + tabid).classList.add('current');
    }


    const buyQuark = (product_id) => {
        if (window.localStorage.getItem('provider') == 'metamask' || window.localStorage.getItem('provider') == 'coinbaseWallet' && (window.localStorage.getItem('WALLETADDRESS') !== null && window.localStorage.getItem('WALLETADDRESS') !== 'null')) {
            handleBuyNowMetamaskAndCoinbase();


            /* const web3Modal = new Web3Modal();
            $(".loaderp").toggleClass("display");
            web3Modal.connect().then((connection) => {
                const provider = new ethers.providers.Web3Provider(connection);
                const signer = provider.getSigner();
                let sender = connection.selectedAddress;
                signer.sendTransaction({
                    to: TRENDSYMARKET_ADDRESS,
                    value: ethers.utils.parseEther("0.0005"),
                }).then((tx) => {
                    console.log(tx);
                    tx.wait().then((receipt) => {
                        $(".loaderp").toggleClass("display");
                        buyingRequest(sender);
                    });
                }).catch(() => {
                    alert('Insufficient Balance');
                    $(".loaderp").toggleClass("display");
                });
            }) */
        }
        else if (window.localStorage.getItem('provider') == 'walletConnect' && window.localStorage.getItem('WALLETADDRESS') !== null && window.localStorage.getItem('WALLETADDRESS') !== 'null') {
            handleBuyNowWC();
        }
        else if (window.localStorage.getItem('provider') == 'null' || window.localStorage.getItem('provider') == null || window.localStorage.getItem('WALLETADDRESS') == null || window.localStorage.getItem('WALLETADDRESS') == 'null') {
            swal('Error', 'Please connect wallets in order to complete transaction', 'error');
        }
    }

    /* start metamask wallet connect */
    const handleBuyNowMetamaskAndCoinbase = async () => {
        $(".loaderp").toggleClass("display");
        let provider = '';
        if (window.localStorage.getItem('provider') == 'metamask') {
            try {
                provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
            } catch (error) {
                provider = window.ethereum;

            }

        }
        else
            provider = window.ethereum;
        provider.enable();
        // Get the user's selected address
        const accounts = await provider.request({ method: 'eth_accounts' });
        const userAddress = accounts[0];
        // startWalletConnectSign(provider, userAddress);
        sendTransaction(provider, userAddress);
    }

    /* start WC wallet connect */
    const handleBuyNowWC = async () => {
        $(".loaderp").toggleClass("display");
        const provider = new WalletConnectProvider({
            infuraId: `${process.env.REACT_APP_INFURA_ID_FOR_WALLET_CONNECT}`,
        });
        provider.enable();
        // Get the user's selected address
        const accounts = await provider.request({ method: 'eth_accounts' });
        const userAddress = accounts[0];
        // startWalletConnectSign(provider, userAddress);
        sendTransaction(provider, userAddress);
    }

    /* starting sign */
    const startWalletConnectSign = async (provider, userAddress) => {
        const message = 'Hello, Marble wallet!';
        const signature = await provider.request({
            method: 'personal_sign',
            params: [message, userAddress, ''], // message, address, passphrase
        });

        console.log('Signature:', signature);
    }

    /* start transaction */
    const sendTransaction = async (provider, userAddress) => {
        if (typeof product.product_serial_details != 'undefined' && product.product_serial_details != '' && product.product_serial_details != 'null' && product.product_serial_details != null) {
            // Transaction object
            let transactionAmount = '';
            if (window.localStorage.getItem('provider') == 'metamask') {
                transactionAmount = Number(ethers.utils.parseEther(typeof product.product_serial_details != 'undefined' ? product.product_serial_details.eth_price : 0)).toString(16);
            } else {
                transactionAmount = ethers.utils.parseEther(typeof product.product_serial_details != 'undefined' ? product.product_serial_details.eth_price : '').toString(); // (in wei)
            }
            const transaction = {
                from: userAddress,
                to: TRENDSYMARKET_ADDRESS,
                value: transactionAmount
                /*  gas: '200000',
                 gasPrice: '50000000000',  */// 50 gwei (in wei)
            };

            // Send the transaction
            try {
                const transactionHash = await provider.request({
                    method: 'eth_sendTransaction',
                    params: [transaction],
                });
                $(".loaderp").toggleClass("display");
                buyingRequest(userAddress); // i think it is buying request
                // console.log('Transaction Hash:', transactionHash);
            } catch (error) {
                $(".loaderp").toggleClass("display");
                swal("Error!", error.message, "error");
            }
        } else {
            swal("Error!", 'Ether cannot be empty', "error");
        }

    }
    /* const buyQuark = (product_id) => {
        const web3Modal = new Web3Modal({
            supportedWallets: ['metamask'],
        });
        web3Modal.connect().then((connection) => {
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            let sender = connection.selectedAddress;

            console.log(sender);
            console.log(product);
            signer.sendTransaction({
                to: TRENDSYMARKET_ADDRESS,
                value: ethers.utils.parseEther("0.0005"),
            }).then((tx) => {
                console.log(tx);
                tx.wait().then((receipt) => {
                    $(".loaderp").toggleClass("display");
                    buyingRequest(sender);
                });
            }).catch(() => {
                alert('Insufficient Balance');
                $(".loaderp").toggleClass("display");
            });
        });
    } */

    const openModal = () => {
        setName("");
        setEmail("");
        $('#WatchList').toggleClass('display');
    }
    const closeModal = () => {
        $('#WatchList').toggleClass('display');
    };

    const addToWatchList = () => {
        alert("Successfully Added to Watchlist.");
        $('#WatchList').toggleClass('display');
    };

    const mintNow = (serial) => {


        let attribute = [{ "trait_type": "Serial No", "value": serial.serial_no },
        { "trait_type": "Collection name", "value": collection.collection_name },
        { "trait_type": "Brand name", "value": brand.brand_name },
        { "trait_type": "Product-Url", "value": "http://marketplace.trendsytech.com/UHJvZHVjdGRldGFpbHM=/" + btoa(serial.uuid) }];

        /* serial.ProductProperties.forEach(function (item, index) {
           let data= {"trait_type":item.key,"value":item.value};
           attribute.push(data);
         });*/

        const data = JSON.stringify({
            name: product.product_name, description: product.product_desc, attributes: attribute, image: serial.product_image
        });
        try {

            let arr = [];
            arr.push(data);
            createJson(arr).then((response) => {
                let url = response.data[0];
                createSale(url, serial.id);
            });
        } catch (e) {

            console.log(e);
        }


    }

    const createSale = (url, id) => {

        const web3Modal = new Web3Modal();
        $(".loaderp").toggleClass("display");
        web3Modal.connect().then((connection) => {
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            let contract = new ethers.Contract(smartContact, TRENDSY.abi, signer);

            const fee = ethers.utils.parseEther("0.09");
            console.log(url);
            contract.connect(signer).userMint(url, {
                value: fee,
                gasPrice: 35000000000,
            }).then((transaction) => {

                transaction.wait().then((receipt) => {
                    const eventFilter = contract.filters.Transfer();
                    contract.queryFilter(eventFilter, receipt.blockHash).then((events) => {
                        let tokenId = events[0].args[2];
                        tokenId = tokenId.toNumber();
                        console.log(tokenId);
                        $(".loaderp").toggleClass("display");
                        update_mint_status(id, transaction.to, tokenId, "000", connection.selectedAddress);
                    });

                });
            });
        });

    }

    const update_mint_status = (serial_id, contract, tokenId, nftmarketaddress_loc, mint_wallet) => {
        $(".loaderp").toggleClass("display");
        const data = { "id": serial_id, nft_count_id: tokenId, contract_id: contract, nftmarketaddress: nftmarketaddress_loc, mint_wallet: mint_wallet };
        updateMintStatus(data).then((response) => {

            console.log('response.status: ', response.status); // 👉️ 200
            console.log(response);
            alert(response.message);
            $(".loaderp").toggleClass("display");
            window.location.reload();


        })
    }



    const buyingRequest = (wallet_address) => {


        let contact_address = product.product_serial_details.contract_id;
        let NFT_tokens = [];
        NFT_tokens.push(product.product_serial_details.nft_count_id);
        $(".loaderp").toggleClass("display");
        const provider = new ethers.providers.JsonRpcProvider(RPCPROVIDER);
        const wallet = new ethers.Wallet(PRIVATE_KEY, provider);

        let contract = new ethers.Contract(contact_address, TRENDSY.abi, wallet);

        contract.connect(wallet).bulkTransfer(TRENDSYMARKET_ADDRESS, wallet_address, NFT_tokens).then((transaction) => {
            transaction.wait().then((receipt) => {
                const eventFilter = contract.filters.bulkTransferCompleted();

                console.log(eventFilter);
                console.log(receipt.blockHash);
                contract.queryFilter(eventFilter, receipt.blockHash).then((events) => {
                    console.log(events);
                    const tokenIdListBigNumber = events[0].args[0];
                    let arr = [];
                    tokenIdListBigNumber.forEach(function (tokenId, index) {
                        tokenId = tokenId.toNumber();
                        arr.push(tokenId);
                    });


                    console.log(arr);
                    $(".loaderp").toggleClass("display");
                    update_buying_status(arr, wallet_address, contact_address);

                });
            });
        });

    }

    const update_buying_status = (tokens_arr, wallet_address, contact_address) => {
        $(".loaderp").toggleClass("display");
        const data = { "tokens_arr": tokens_arr, wallet_address: wallet_address, contact_address: contact_address };
        console.log(data);
        updateBuyingStatus(data).then((response) => {

            console.log('response.status: ', response.status); // 👉️ 200
            alert(response.message);
            $(".loaderp").toggleClass("display");
            window.location.reload();
        })
    }


    return (
        <>
            <section className="details_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="product_details_ar">
                                <div className="product_details_picture_ar">
                                    {typeof product.product_serial_details != 'undefined' && (
                                        <img style={{ maxHeight: '370px' }} src={product.product_serial_details.product_image} alt="" />
                                    )}
                                </div>
                                <div className="product_details_conty_ar">
                                    <div className="product_details_conty_nav">
                                        <div className="product_details_conty_nav_bg">
                                            <ul className="product_details_conty_nav_list">
                                                <li><a href={void (0)} data-tab="prdcontyTab-1" onClick={changeTab} className="current">Overview</a></li>
                                                <li><a href={void (0)} data-tab="prdcontyTab-2" onClick={changeTab} className="">Properties<span className="product_tab_s_b">{typeof product.product_serial_details != 'undefined' ? product.product_serial_details.trnd_product_properties.length : 0}</span></a></li>

                                                {collection.minting_type == "mint_nft_brand_metal" && (
                                                    <li><a href={void (0)} data-tab="prdcontyTab-3" onClick={changeTab} className="">Metal Prices</a></li>

                                                )} {/* <li><a href="#" data-tab="prdcontyTab-4" className="">History</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product_details_conty_desc">
                                        <div className="product_details_conty_desc_inner_block current" id="prdcontyTab-1">
                                            <div className="details_block_gen">
                                                <p className="heading_gen">Description</p>
                                                <p className="text_gen">{collection.collection_description != '' && collection.collection_description != null ? collection.collection_description : ''}</p>
                                            </div>
                                            <div className="details_block_gen">
                                                <p className="heading_gen">Details</p>
                                                <div className="section_bordered_details_ar">
                                                    <p className="section_bordered_details_ar_row">
                                                        <span><img src="/assets/img/d1.png" alt="" /></span>
                                                        <span>Ethereum (ERC-721)</span>
                                                    </p>
                                                    {/* <p className="section_bordered_details_ar_row">
                                                        <a href="#">
                                                            <span><img src="/assets/img/d2.png" alt="" /></span>
                                                            <span>View on Etherscan</span>
                                                            <span className="d_arr"><img src="/assets/img/d_arr.png" alt="" /></span>
                                                        </a>
                                                    </p>
                                                    <p className="section_bordered_details_ar_row brdsep">
                                                        <a href="#">
                                                            <span><img src="/assets/img/d3.png" alt="" /></span>
                                                            <span>Open original</span>
                                                            <span className="d_arr"><img src="/assets/img/d_arr.png" alt="" /></span>
                                                        </a>
                                                    </p> */}
                                                    <p className="section_bordered_details_ar_row">
                                                        <a href="#">
                                                            <span><img src="/assets/img/d4.png" alt="" /></span>
                                                            <span>Refresh Metadata</span>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product_details_conty_desc_inner_block" id="prdcontyTab-2">
                                            <div className="properties_block">
                                                <div className="properties_block_row">
                                                    <a href="#">
                                                        <div className="properties_block_row_sec_1">Name</div>
                                                        <div className="properties_block_row_sec_2">Rarity</div>
                                                        <div className="properties_block_row_sec_3"></div>
                                                    </a>
                                                </div>
                                                {typeof product.product_serial_details != 'undefined' && (
                                                    product.product_serial_details.trnd_product_properties.map((item, i) => {
                                                        return (
                                                            <div className="properties_block_row pbbp" key={i}>
                                                                <a href="#">
                                                                    <div className="properties_block_row_sec_1">
                                                                        <p>Property</p>
                                                                        <p>{item.key}</p>
                                                                    </div>
                                                                    <div className="properties_block_row_sec_2">{item.value}</div>
                                                                    <div className="properties_block_row_sec_3"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                                                                </a>
                                                            </div>
                                                        )
                                                    })
                                                )}

                                            </div>
                                        </div>
                                        <div className="product_details_conty_desc_inner_block" id="prdcontyTab-3">
                                            <div className="properties_block">
                                                <div className="properties_block_row">
                                                    <a href="#">
                                                        <div className="properties_block_row_sec_1">Name</div>
                                                        <div className="properties_block_row_sec_2">Price</div>
                                                        <div className="properties_block_row_sec_3"></div>
                                                    </a>
                                                </div>
                                                {typeof product.product_serial_details != 'undefined' && (
                                                    product.product_serial_details.trnd_product_properties.map((item, i) => {
                                                        return (
                                                            metalList.map((metal, index) => {
                                                                return (
                                                                    metal.metal == item.key.slice(0, item.key.indexOf('(', 0)).trim() && (
                                                                        <div className="properties_block_row pbbp" key={i}>
                                                                            <a href="#">
                                                                                <div className="properties_block_row_sec_1">
                                                                                    <p>Metal Name</p>
                                                                                    <p>{metal.metal}</p>
                                                                                </div>
                                                                                <div className="properties_block_row_sec_2">{parseFloat(metal.price).toFixed(6)}</div>
                                                                                <div className="properties_block_row_sec_3"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                )
                                                            })
                                                        )
                                                    })
                                                )}

                                            </div>
                                        </div>
                                        <div className="product_details_conty_desc_inner_block" id="prdcontyTab-4">
                                            4
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="details_right_block">
                                <div className="details_heading_block">
                                    <p className="details_user_sec">
                                        <span><img src={collection.collection_image} alt="" /></span>
                                        <Link to={'/collection/' + btoa(collection.id)}>
                                            <span>{collection.collection_name}</span>
                                        </Link>
                                    </p>
                                    <p className="details_heading">{product.product_name}</p>
                                    <div className="creator_section">
                                        <div className="creator_section_left">
                                            <div className="creator_section_inner_1">
                                                <a href="#"><img src="/assets/img/thumb_bg_small.png" alt="" /></a>
                                            </div>
                                            <div className="creator_section_inner_2">
                                                <p>Creator</p>
                                                <p><Link to={'/brand/' + btoa(brand.id)}>{brand.brand_name}</Link></p>
                                            </div>
                                        </div>
                                        {typeof product.product_serial_details != 'undefined' && (
                                            product.product_serial_details.buying_status == 1 && product.product_serial_details.claim != '' && product.product_serial_details.claim != null && (
                                                <div className="creator_section_right">
                                                    <div className="creator_section_inner_1">
                                                        <a href="#"><img src="/assets/img/thumb_bg_small_2.png" alt="" /></a>
                                                    </div>
                                                    <div className="creator_section_inner_2">
                                                        <p>Current owner</p>
                                                        <p><a href="#">{product.product_serial_details.claim}</a></p>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className="price_block">
                                <div className="price_setion">
                                    <p className="price_small_txt">Price</p>
                                    <p className="price_value">{typeof product.product_serial_details != 'undefined' ? parseFloat(product.product_serial_details.eth_price).toFixed(6) : ''} (ETH) <div>{typeof product.product_serial_details != 'undefined' ? parseFloat(product.product_serial_details.price).toFixed(2) : ''}(USD)</div></p>
                                    <p className="price_details_txt"><span>By</span><a href="#">Trendsy</a></p>
                                </div>

                                {typeof product.product_serial_details != 'undefined' && (
                                    product.product_serial_details.buying_status != 1 && (product.product_serial_details.claim == '' || product.product_serial_details.claim == null) && (
                                        <div className="price_btn">
                                            {collection.minting_type == "mint_nft_brand_metal" && (
                                                <button className="common_full_button" onClick={() => { buyQuark(product.product_serial_details.id) }}>Buy now</button>
                                            )}
                                            {collection.minting_type == "mint_nft_brand" && (
                                                <button className="common_full_button" >Claim now</button>
                                            )}
                                            {collection.minting_type == "mint_nft_collector" && product.product_serial_details.minting_status != 1 && (
                                                <button className="common_full_button" onClick={() => { mintNow(product.product_serial_details) }} >Mint now</button>
                                            )}
                                            {collection.minting_type == "mint_nft_collector" && product.product_serial_details.minting_status == 1 && (
                                                <button className="common_full_button disabled"  >Already Minted</button>
                                            )}


                                            {collection.minting_type == "incompleted_nft_watch_list" && (
                                                <button onClick={() => { openModal() }} className="common_full_button" >Add To WatchList</button>
                                            )}
                                        </div>
                                    )
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cara_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading_cara"><p className="heading_gen">More from this collection</p></div>
                            <div className="cara_inner">
                                {!isLoading && (
                                    <OwlCarousel className='owl-theme' responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 5
                                        }
                                    }} loop autoplay={true} nav={false} margin={10}>
                                        {typeof productListOfCollection != 'undefined' && productListOfCollection.length > 0 && (
                                            productListOfCollection.map((getproduct, i) => {
                                                return (
                                                    getproduct.serial.map((item, j) => {
                                                        return (
                                                            item.uuid != product.product_serial_details.uuid && (
                                                                <div className="item" key={j}>
                                                                    <div className="product_display_thumb">
                                                                        <div className="product_display_image_thumb">
                                                                            <img src={item.product_image} alt="" />
                                                                        </div>
                                                                        <div className="product_display_details">
                                                                            <div className="product_display_details_left">
                                                                                <span>Product Name</span>
                                                                                <span>{getproduct.product_name}</span>
                                                                            </div>
                                                                            <div className="product_display_details_righr">
                                                                                <span>Price</span>
                                                                                <span>{parseFloat(item.eth_price).toFixed(6)}(ETH)</span>
                                                                                <span>{parseFloat(item.price).toFixed(2)}(USD)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product_display_button">

                                                                            {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                        )
                                                    })
                                                )
                                            })
                                        )}
                                    </OwlCarousel>
                                )}
                            </div>
                            <div className="cara_btn">
                                <Link to={'/collection/' + btoa(collection.id)}><button className="btn btn-light btn-lg brand_cta_btn">View Collection</button></Link>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <div id="WatchList" className="modal " tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog max-width-300">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel"></h5>
                            <button type="button" id="WatchList-close" className="btn-close" onClick={() => { closeModal() }}
                                aria-label="Close"> X </button>
                        </div>
                        <div className="modal-body">
                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">

                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Add to WatchList</h5>
                                            </div>
                                            {/* action={"UHJvZHVjdGRldGFpbHN3YWxsZXQ=/"+this.state.product_serial_uuid} */}
                                            <form className="row col-md-12" noValidate >

                                                <div className="col-md-12">

                                                    <div className="col-md-6">
                                                        <label for="input-username">Name</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input type="text" className="form-control"
                                                            id="input-username" placeholder="Enter  Name" onChange={e => setName(e.target.value)} />
                                                        <div className="form-floating-icon">
                                                            <i data-eva="people-outline"></i>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-md-12">



                                                    <div className="col-md-6">
                                                        <label for="input-username">Email</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input type="text" className="form-control"
                                                            id="input-username" placeholder="Enter  Email" onChange={e => setEmail(e.target.value)} />
                                                        <div className="form-floating-icon">
                                                            <i data-eva="people-outline"></i>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-md-12" style={{ marginTop: '20px' }}>
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="button" onClick={() => { addToWatchList() }}>Add</button>
                                                </div>

                                            </form>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Product
