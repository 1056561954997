

let url_base=`${process.env.REACT_APP_API_URL}`;
var host = window.location.host;
if(host!="http://54.147.228.241"){
 url_base=`${process.env.REACT_APP_API_URL_1}`;
}


let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export async function getBrandDetails(brand_id) {
    const response = await fetch(url_base+`api/collection-list`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ brand_id: brand_id })
    })
    return await response.json();
}

/* get first product for every collection */
export async function getProductOfEachCollection(collection_id_array) {
    const response = await fetch(url_base+`api/first-product-of-each-collection`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ collection_id_array: collection_id_array })
    })
    return await response.json();
}

/* get first product for every collection */
export async function getMyMetals(wallet_address,brand_id) {
    const response = await fetch(url_base+`api/getMyMetals`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ wallet_address: wallet_address,brand_id:brand_id })
    })
    return await response.json();
}


export async function getAllBrands() {
    const response = await fetch(url_base+'api/brand-list', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({user_id: 1})
      })
    return await response.json();
    
}

