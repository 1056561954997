import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import BannerAndLogo from '../../components/BannerAndLogo';
import UserDetails from '../../components/UserDetails';
import { encrypt, getQueryData } from '../../helper/Helper';
import { getAllBrands } from '../../services/BrandService';
import { getCollectionList, getSmartContactId } from '../../services/CollectionService';
import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

const Home = (props) => {
    const [brand, setBrand] = useState([]);
    const [collection, setCollection] = useState([]);
    const [productList, setProductList] = useState([]);
    const [smartContact, setSmartContact] = useState([]);
    document.title = "Trendsy Marketplace";
    
    let url_base=window.location.origin;
    
   // Create connector
   const walletConnector = new NodeWalletConnect(
    {
      bridge: "https://bridge.walletconnect.org", // Required
    },
    {
      clientMeta: {
        description: "WalletConnect NodeJS Client",
        url: "https://nodejs.org/en/",
        icons: ["https://nodejs.org/static/images/logo.svg"],
        name: "WalletConnect",
      },
    }
  );
  
  // Check if connection is already established
 
  
  // Subscribe to connection events
  walletConnector.on("connect", (error, payload) => {
    if (error) {
      throw error;
    }
  
    // Close QR Code Modal
    WalletConnectQRCodeModal.close(
      true // isNode = true
    );
  
    // Get provided accounts and chainId
    const { accounts, chainId } = payload.params[0];
    alert("connected");
    console.log(accounts);
  });
  
  walletConnector.on("session_update", (error, payload) => {
if (error) {
throw error;
}

// Get updated accounts and chainId
const { accounts, chainId } = payload.params[0];
alert("connected");
    console.log(accounts);
});

walletConnector.on("disconnect", (error, payload) => {
if (error) {
throw error;
}
alert("disconnect");
    
// Delete walletConnector
});
  function openwallet() {
    if (!walletConnector.connected) {
        // create new session
        walletConnector.createSession().then(() => {
          // get uri for QR Code modal
          const uri = walletConnector.uri;
          // display QR Code modal
          WalletConnectQRCodeModal.open(
            uri,
            () => {
              console.log("QR Code Modal closed");
            },
            true // isNode = true
          );
        });
      }
  }
    useEffect(() => {
      
      getAllBrands().then((response) => {
        if (response.code == 200) {
          setBrand(response.data.brand_list)
          
        }
      });
    }, []);
    return (
        <>
            
            {/* product section */}
            <section className="details_section">
                <div className="container-fluid">
                    <div className="row" style={{paddingBottom:'50px'}}>
                    <div className="col-md-2">
                        </div>
                        <div className="col-md-8">
                            <div className="product_details_ar">
                            <div class="row">
                            <div class="col-md-12">
                                <div class="error-template">
                                    <h1>
                                    NFT Creator Listed With Trendsy </h1>
                                    <div className="row" style={{marginTop:'20px'}}>
                                    {brand.map((item, index) => {
                                                                        return (
                                                                         
                                                                            <div className="col-md-4 product_display_blk" key={'productListSection' + index}>
                                                                               <a  href={url_base+"/brand/" + btoa(item.id)}>
                                                                                <div className="product_display_thumb">
                                                                                    <div className="product_display_image_thumb">
                                                                                        <img src={item.brand_image} alt="product image"  style={{'width':'100%','height':'180px'}}/>
                                                                                    </div>
                                                                                    <div className="product_display_details">
                                                                                        <div className="product_display_details_left">
                                                                                           
                                                                                            <span>{item.brand_name}</span>
                                                                                             </div>
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                </a>
                                                                            </div>
                                                                           
                                                                        )
                                                                    })}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                </div>
                </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Home
