import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import BannerAndLogo from '../../components/BannerAndLogo'
import UserDetails from '../../components/UserDetails'
import { getQueryData } from '../../helper/Helper';
import { getBrandDetails, getProductOfEachCollection,getMyMetals } from '../../services/BrandService';
import $ from 'jquery'
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import TRENDSY from '../../contracts/Trendsy.json';
import {
    TRENDSYMARKET_ADDRESS,PRIVATE_KEY,RPCPROVIDER
  } from '../../config.js';

const Brand = (props) => {
  const [bannerimage, setbannerimage] = useState('');
  const [brandimage, setbrandimage] = useState('');
  const [brandname, setbrandname] = useState('');
  const [mynft, setmynft] = useState('');
  const [mycollection, setmycollection] = useState('');
  const [walletAddress, setwalletAddress] = useState([]);
  const [branddescription, setbranddescription] = useState('');
  const [smartcontact, setsmartcontact] = useState('');
  const [productList, setproductList] = useState([]);
  const [siteLink, setsiteLink] = useState('');
  const [walletAddress2, setwalletAddress2] = useState([]);
  document.title = "Brands"
  const location = useLocation();



  
  /* ON COMPONENT MOUNT */
  useEffect(() => {


    const WALLETADDRESS = window.localStorage.getItem("WALLETADDRESS");
    if (WALLETADDRESS != null && WALLETADDRESS != undefined) {
        setwalletAddress(WALLETADDRESS);

        getMyMetals(WALLETADDRESS,getQueryData()).then((response) => {
          if (response.code == 200) {
            setmynft(response.data.nft)
            var res=[];
            for(var i in response.data.collection){
              if(response.data.collection[i].is_deleted!=1){
                
                var count = response.data.collection_count.filter(x => x == response.data.collection[i].id).length;
                response.data.collection[i].count=count;
                res.push(response.data.collection[i]);
              }
             
            }
                
            setmycollection(res)
            console.log(mycollection);
          }
        });
        
    }

    

    $(".open_filter").on('click', function (e) {
      e.preventDefault();
      $("body").addClass("reveal-filter");
  });

  $(".close_filter").on('click', function (e) {
      e.preventDefault();
      $("body").removeClass("reveal-filter");
  });

  
    getBrandDetails(getQueryData()).then((response) => {
      if (response.code == 200) {
        let collection_id_array = [];
        setbannerimage(response.data.brand.banner_image)
        setbrandimage(response.data.brand.brand_image)
        setbrandname(response.data.brand.brand_name)
        setbranddescription(response.data.brand.brand_description)
        setsmartcontact(response.data.brand.smart_contact.contract_id)
        response.data.collection_list.forEach(element => {
          collection_id_array.push(element.id);
        });
        getProductOfEachCollection(collection_id_array).then((response) => {
          if (response.code == 200) {
            setproductList(response.data);
          }
        })
      }
    });
    /* if want to display cta or not */
    props.onChangeCta(true);
    $('.accordion-list > li > h3').next(".answer").hide();
    setsiteLink(window.location.href)
  }, [location]);


  const claim_collection=(collection_id)=>{
    console.log("aaa"+collection_id);
   const web3Modal = new Web3Modal();
    web3Modal.connect().then((connection)=>{
    let sender=connection.selectedAddress;
    window.localStorage.setItem("WALLETADDRESS", sender);
    window.localStorage.setItem("COLLECTIONID", collection_id);
      $('#myModal').toggleClass('display');
    });
  }

  /* tab changer */
  const tabChanger = (event) => {
    let element = event.currentTarget;
    let tab_id = element.getAttribute('data-tab');
    $('ul.product_tab li a').removeClass('current');
    $('.prdBlock').removeClass('current');
    $(this).addClass('current');
    $("#" + tab_id).addClass('current');
  }
  const tabChanger2 = (event) => {
    let element = event.currentTarget;
    let tab_id = element.getAttribute('data-tab');
    $('ul.product_tab li a').removeClass('current1');
    $('.prdBlock').removeClass('current1');
    $(element).addClass('current1');
    $("#" + tab_id).addClass('current1');
}
  /* toggle dropdown */
  const toggleDropdown = (event) => {
    let element = event.currentTarget;
    if ($(element).hasClass("active")) {
      $(element).removeClass("active").next(".answer").slideUp();
    } else {
      $(".accordion-list > li > h3.active").next(".answer").slideUp();
      $(".accordion-list > li > h3.active").removeClass("active");
      $(element).addClass("active").next(".answer").slideDown();
    }
    return false;
  }

  

  /* collection price */
  const collection_price = (collection_product_list) => {
    let buying_list = [];
    collection_product_list.forEach(function (product, index) {
      product.serial.forEach(function (serial, index) {
        if (serial.buying_status == 0) {
          buying_list.push(serial);
        }
      });
    });
    return buying_list;
  }

  return (
    <>
      <BannerAndLogo
        banner={bannerimage}
      
      />
      {/* user details */}
      <UserDetails
        name={brandname}
        description={branddescription}
        smart_contact={smartcontact}
        needDownAddress={true}
        site_link={siteLink}
        logo={brandimage}
        minting_type="mint_nft_brand_metal"
      />

      {/* product section */}
      <section className="product_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="product_nav_section">
                <ul className="product_tab">
              
                  <li><a href="javascript:;" onClick={tabChanger} data-tab="prdTab-1" className="current">Marketplace</a></li>
                  <li><a href="javascript:;" onClick={tabChanger} data-tab="prdTab-2" className="">My Nfts</a></li>
                  <li><a href="javascript:;" onClick={tabChanger} data-tab="prdTab-3" className="">How it works</a></li>
              
                </ul>
              </div>
              <div className="product_conty_section">
                <div className="prdBlock current" id="prdTab-1">
                  <div className="product_top_block">
                    <div className="refresh_button mr-3 open_filter">
                      <button><i className="fa fa-filter" aria-hidden="true"></i></button>
                    </div>
                    <div className="custom_select_box">
                      <div className="dropdown">
                        <button className="btn btn_drop_custm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                          <span>Recently listed</span>
                          <i className="fa fa-caret-down"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li><a className="dropdown-item" href="#">Australia</a></li>
                          <li><a className="dropdown-item" href="#">India</a></li>
                          <li><a className="dropdown-item" href="#">United States</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="refresh_button">
                      <button><i className="fa fa-refresh" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <div className="product_display_section">
                    <div className="row">
                      <div className="col-md-4">

                        <div className="filter_area panel__filter__container">
                          <p className="filter_heading">Filter</p>
                          <a href="#" className="close_filter">X</a>
                          <ul className="accordion-list">
                            <li>
                              <h3 onClick={toggleDropdown} >Status</h3>
                              <div className="answer">
                                <div className="small_bubble_ar">
                                  <button className="small_bubble">All</button>
                                  <button className="small_bubble">Buy Now</button>
                                  <button className="small_bubble">Open for Offers</button>
                                </div>
                              </div>
                            </li>
                            <li>
                              <h3 onClick={toggleDropdown} >Price</h3>
                              <div className="answer">
                                <div className="price_filter">
                                  <span><input type="text" placeholder="min" /></span>
                                  <span>to</span>
                                  <span><input type="text" placeholder="max" /></span>
                                </div>
                                <div className="price_block_btn">
                                  <button className="common_full_button">Apply</button>
                                </div>
                              </div>
                            </li>
                            <li>
                              <h3 onClick={toggleDropdown} >Type</h3>
                              <div className="answer">
                                <div className="small_bubble_ar">
                                  <button className="small_bubble">All</button>
                                  <button className="small_bubble">Buy Now</button>
                                  <button className="small_bubble">Open for Offers</button>
                                </div>
                              </div>
                            </li>
                            <li>
                              <h3 onClick={toggleDropdown} >Collections</h3>
                              <div className="answer">
                                <div className="collection_block">
                                  {productList.length > 0 && productList.map((collections, i) => {
                                    return (
                                      <div className="collection_block_row" style={{ "textDecoration": "none" }} key={i}>
                                        <div className="coll_thumb"><img src={collections.collection.collection_image} alt="" /></div>
                                        <div className="coll_details">
                                          <Link to={`/collection/` + btoa(collections.collection.id)} key={i} style={{ "textDecoration": "none" }} >
                                            <div className="coll_details_top">
                                              <div className="coll_details_top_l">{collections.collection.collection_name}</div>
                                              <div className="coll_details_top_r">{collection_price(collections.product_list).length > 0 ? (collection_price(collections.product_list).length * collection_price(collections.product_list)[0].eth_price).toFixed(6) + ' (ETH)' : ''} </div>
                                            </div>
                                          </Link>
                                          
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="product_display_thumb_ar">
                          <div className="row">
                            {productList.length > 0 && productList.map((data1, i) => {
                              return (
                                data1.product_list.slice(0, 1).map((product, j) => {
                                  return (
                                    product.serial.slice(0, 1).map((serialproduct, k) => {
                                      return (
                                        <div className="col-md-4 product_display_blk" key={k}>
                                          <div className="product_display_thumb">
                                            <div className="product_display_image_thumb">
                                              <img src={serialproduct.product_image} alt="Product Image" />
                                            </div>
                                            <div className="product_display_details">
                                              <div className="product_display_details_left">
                                                <span>Product Name</span>
                                                <span>{product.product_name}</span>
                                              </div>
                                              <div className="product_display_details_righr">
                                                <span>Price</span>
                                                <span>{parseFloat(serialproduct.eth_price).toFixed(4)}(ETH) ({parseFloat(serialproduct.price).toFixed(2)} USD)</span>
                                              
                                              </div>
                                            </div>
                                            <div className="product_display_details product_display_details_mobile">
                                              <div className="product_display_details_left">
                                                <span>Collection Name</span>
                                                <Link to={'/collection/' + btoa(data1.collection.id)} ><span>{data1.collection.collection_name}</span></Link>
                                              </div>
                                              <div className="product_display_details_righr">
                                                <span>Serial Number</span>
                                                <span>{serialproduct.serial_no}</span>
                                              </div>
                                            </div>

                                            <div className="product_display_button">
                                              <Link to={'/product/' + btoa(serialproduct.uuid)}><button className="common_full_button">{data1.collection.minting_type=='mint_nft_brand_metal'?'View Quark':'View Product'}</button></Link>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  )
                                })
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prdBlock" id="prdTab-2">
                {(walletAddress != '' && walletAddress != null && walletAddress != "null") && (
                 <div>
                  <h3>My metals <span style={{color:'green',fontSize:'14px'}}>(Wallet Connected)</span></h3>
                <div className="row">
                  
                <div className="col-md-12">
                    
                     <div className="product_display_thumb_ar">
                     
                  
                     <div className="product_nav_section">
                                <ul className="product_tab">
                                   
                                        <>
                                        <li><a href="javascript:void(0)" onClick={tabChanger2} id="collection_tab" data-tab="prdTab-20" className="current1">Collections</a></li>
                                      
                                            <li><a href="javascript:void(0)" onClick={tabChanger2} id="nft_tab" data-tab="prdTab-10" className="">Nfts</a></li>
                                         </>
                                  
                                </ul>
                            </div>
                        </div>

           
{/* quark */}           
          <div className={'prdBlock '} id="prdTab-10">
              <div className="product_display_section">
                  <div className="row">
                      <div className="col-md-12">
                       <div className="product_display_thumb_ar">
                          <div className="row">
                            {mynft.length > 0 && mynft.map((serial, i) => {
                              return (
                                      <div className="col-md-3" key={i}>
                                          <div className="product_display_thumb">
                                            <div className="product_display_image_thumb">
                                              <img src={serial.product_image} alt="Product Image" />
                                            </div>
                                            <div className="product_display_details">
                                              <div className="product_display_details_left">
                                                <span>Owner</span>
                                                <span>{serial.claim}</span>
                                              </div>
                                              <div className="product_display_details_righr">
                                                <span>Price</span>
                                                
                                                <span>{parseFloat(serial.eth_price).toFixed(4)}(ETH) ({parseFloat(serial.price).toFixed(2)} USD)</span>
                                              </div>
                                            </div>
                                            <div className="product_display_details">
                                              <div className="product_display_details_left">
                                                <span>Collection Name</span>
                                                <Link to={'/collection/' + btoa(serial.collection_id)} ><span>{serial.collection__name}</span></Link>
                                              </div>
                                              <div className="product_display_details_righr">
                                                <span>Serial Number</span>
                                                <span>{serial.serial_no}</span>
                                              </div>
                                            </div>

                                            <div className="product_display_button">
                                              <Link to={'/product/' + btoa(serial.uuid)}><button className="common_full_button">View Quark</button></Link>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                   
                              
                            })}
                          </div>
                        </div>

                      </div>
                      </div>
                        </div>
                        </div> 

{/* collection */}
                        <div className={'prdBlock current1'} id="prdTab-20">
              <div className="product_display_section">
                  <div className="row">
                      <div className="col-md-12">
                       <div className="product_display_thumb_ar">
                       <div className="row">

{ mycollection.length > 0 && mycollection.map((collection, index) => {
   return (
        <div className="col-md-4">
            <div className="activity_block_row" key={'collectionListSection' + index}>
                <div className="activity_block_image_thumb activity_block_image_thumb_mod">
                    <a href="#">
                        <img src={collection.collection_image} alt="" />
                    </a>
                    <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                </div>
                <div className="activity_block_conty">
                    <p className="activity_heading"><Link to={'/collection/' + btoa(collection.id)}>{collection.collection_name}</Link></p>
                    <p className="">You owned {collection.count}/10 Quark</p>

                     {collection.claim_count>0 && ( <div className="product_display_button">
                     <p className=""> <span style={{background:'rgb(28 39 91)',padding:"2px",color:'#fff'}}>This Collection Already Claimed</span></p>    
   </div>)}
   {collection.claim_count>0  && ( <div className="product_display_button">
                     <p className=""> <span style={{padding:"2px"}}>Shipping Status :
                      <b>
                        {collection.claim_request.shipping_status==1&&(<span>Completed</span>)}
                        {collection.claim_request.shipping_status==2&&(<span>Failed</span>)}
                        {collection.claim_request.shipping_status!=1 && collection.claim_request.shipping_status!=2&&(<span>Pending</span>)}
                      </b></span></p>    
   </div>)}
                  
               
              
                
                
                <div className="product_display_button">
                        <Link to={'/collection/' + btoa(collection.id)}><button className=" common_full_button">View Collection</button></Link>
                    </div>
                    {collection.count==10 && collection.claim_count==0 && ( <div className="product_display_button">
                      <button style={{background:'rgb(28 39 91)'}} className="common_full_button"  onClick={() => { claim_collection(collection.id) }} >Claim Your Collection</button>
   </div>)}
   </div>
                  </div>
                  
           
        </div>
    )
})}

</div>
                        </div>

                      </div>
                      </div>
                        </div>
                        </div> 
                     
                     
                      </div>
                  

                </div>
              </div>
                )}
                {(walletAddress == '' || walletAddress == null || walletAddress == "null") && (
                
                <div>
                <h3>My metals <span style={{color:'gray',fontSize:'14px'}}>(Wallet Disconnected)</span></h3>
                <div className="row">
                  
                <div className="col-md-12">
                  <p>Please connect your wallet first to view your metals</p>
                  <a href="/connect-wallet" className="btn btn-light btn-lg wallet__btn"> Connect Wallet </a>
                </div>
                </div>
                </div>
                )}
                </div>
                <div className="prdBlock" id="prdTab-3"> <h3>Comming Soon</h3>  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Brand
