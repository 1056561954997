import React, { Component } from 'react';
import {getRequestDetails,getPaymentAccessToken,sendPayment}  from '../../services/PaymentService';
class PaymentSuccess extends Component {


    constructor(props) {
        super(props)
        const pathArray = window.location.pathname.split('/');
        const request_id =pathArray[3];
        this.state = {
            id:0,
            brand_id:0,
            request_id:request_id,
            loader:false,
            claimDetails:'',
            accessToken:'',
            expYear:'',
            expMonth:'',
            region:'',
            postalCode:'',
            streetAddress:'',
            country:'',
            city:'',
            cardnumber:'',
            cardcvc:'',
            cardname:'',
            isError:{
                responseErr:''
            }
        }
       // this.getRequestDetails(request_id);
        //this.getPaymentAccessToken();
    }
    formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    };

    getRequestDetails = (id) => {
        getRequestDetails(id).then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                let isError = {...this.state.isError}
                isError.responseErr = response.error;
                this.setState({isError});
            }else{
                this.setState({
                    claimDetails: response.data,
                });
               
                
                
            }
                 })
    };

    getPaymentAccessToken = () => {
        getPaymentAccessToken().then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                let isError = {...this.state.isError}
                isError.responseErr = response.error;
                this.setState({isError});
            }else{
                this.setState({
                    accessToken: response.accessToken,
                });
               
                
                
            }
                 })
    };

   

    submitPayment=()=>{
     let data={
            "amount": this.state.claimDetails.shipping_fee,
            "card": {
               "expYear": this.state.expYear,
               "expMonth": this.state.expMonth,
               "address": {
                     "region": this.state.region,
                     "postalCode": this.state.postalCode,
                     "streetAddress": this.state.streetAddress,
                     "country": this.state.country,
                     "city": this.state.city,
               },
               "name": this.state.cardname,
               "cvc":this.state.cardcvc,
               "number": this.state.cardnumber,
            },
            "currency": "USD",
            "context": {
               "mobile": "false",
               "isEcommerce": "true"
            }
         };

         sendPayment(data,this.state.accessToken).then((response)=>{
            this.setState({loader: false});
            console.log(response);
            if(response.status=='error' || response.code=='401' ){
                let isError = {...this.state.isError}
                isError.responseErr = response.error;
                this.setState({isError});
            }else{
                console.log(response.data);
                alert("Payment Done Successfully");
                //confirmAlert({title: 'ALert!',message:"Payment Done Successfully",buttons: [ {label: 'OK'}]});
                
                
            }
                 })
    }
    render () {
        const {loader} = this.state;
        return (
<div className="main-content main-content-override bg-dark">
            <div className="page-content page-content-notop">
           
            <div className="container-fluid">
          
           
           

            <div className="row text-center">
            <div className="col-lg-12 margin-13">
            <div className="card padding-top-bottom-12">
                <div className="card-body ">
                <div className="jumbotron text-center container">
  <h1 className="display-3">Thank you!</h1>
  <p className="lead"><b>Your payment is being processed. </b> For buying more NFT please visit our NFT collection page.</p>
  <hr/>
  
  <p className="lead">
    <a className="btn btn-success btn-sm" href="http://54.147.228.241/home/" role="button">Continue to Home Page</a>
  </p>
  <p>
    Having trouble? <a href="http://54.147.228.241/">Contact us</a>
  </p>
</div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
        );
    }
}

export default PaymentSuccess;