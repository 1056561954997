let url_base=`${process.env.REACT_APP_API_URL}`;
var host = window.location.host;
if(host!="http://54.147.228.241"){
 url_base=`${process.env.REACT_APP_API_URL_1}`;
}


let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
export async function getProductRelatedData(product_id_base_64) {
    const response = await fetch(url_base+`api/product-serial-details`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ id: product_id_base_64 })
    })
    return await response.json();
}


export async function updateMintStatus(data) {
    const response = await fetch(url_base+'api/update-mint-status', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })
    return await response.json();
  }

  export async function createJson(data) {
    const response = await fetch(url_base+'api/create-json-file', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({json_arr: data })
      })
    return await response.json();
  }