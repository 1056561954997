import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useWeb3React } from "@web3-react/core";
import { connectors, truncateAddress } from "./../pages/wallet/connectors";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { getAllCollectionList, getMetalList } from '../services/CollectionService';
import { Link, useLocation } from 'react-router-dom';
import { LoadingSpinner } from './loader';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const Header = () => {
    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                }
            }
        },
    };

    const labels = ['', '', '', '', '', '', ''];

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                data: [10, 20, 30, 40, 5, 90, 150],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',

            }
        ],
    };
    const [SearchVisibity, setSearchVisibity] = useState(false);
    const [collectionList, setgetAllCollectionList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [walletAddress, setwalletAddress] = useState([]);
    const [searchResultsByCollection, setSearchResultsByCollection] = useState([]);
    const [searchResultsByProduct, setSearchResultsByProduct] = useState([]);
    const [metal_list, setMetalList] = useState('');

    const location = useLocation();
    const closeModal = (btn, collection_id) => {
        $('#myModalspot').toggleClass('display');
    };
    useEffect(() => {
        const WALLETADDRESS = window.localStorage.getItem("WALLETADDRESS");
        if (WALLETADDRESS != null && WALLETADDRESS != undefined) {
            setwalletAddress(WALLETADDRESS);
            const provider = window.localStorage.getItem("provider");

            if (/* provider == "walletConnect" || */ provider == "coinbaseWallet") {
                if (localStorage.getItem('WALLETADDRESS') != null && localStorage.getItem('WALLETADDRESS') != 'null')
                    activate(connectors[provider]);
            } else if (provider == "metamask") {

            }
        }

        $(window).resize(function () {
            $("body").removeClass("reveal-menu");
            $("body").removeClass("reveal-filter");
            $("body").removeClass("reveal-header-search");
        });

        $(document).on("click", function (event) {
            if (!$(event.target).closest("#sliding__menu").length) {
                $("body").removeClass("reveal-menu");
            }
        });

        /* fetching all colletion list from DB */
        getAllCollectionList().then((response) => {
            if (response.code == 200) {
                setgetAllCollectionList(response.data.collection_list)
            }
        })

        getMetalList().then((response) => {

            response.data.trndMetalPrice.forEach(function (metal, index) {
                let price = metal.price;
                let labels = ['', '', '', '', '', '', ''];
                let data = {
                    labels,
                    datasets: [
                        {
                            fill: true,
                            data: metal.data,
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',

                        }
                    ],
                };

                response.data.trndMetalPrice[index]['data'] = data;

            });
            setMetalList(response.data.trndMetalPrice)
        });


    }, [])

    const toggleMenu = (e) => {
        e.stopPropagation();
        $("body").toggleClass("reveal-menu");
    }

    const openHeaderSearch = (event) => {
        event.preventDefault();
        $("body").addClass("reveal-header-search");
    }

    const closeHeaderSearch = (event) => {
        event.preventDefault();
        $("body").removeClass("reveal-header-search");
    }

    const searchBoxChange = (event) => {
        setSearchValue(event.target.value);
        var searchResultsList = [];

        /* user need to type 3 charecter to searchbox to see dropdown */
        if (event.target.value != '' && event.target.value.length > 2) {
            setSearchVisibity(true);
            collectionList.forEach((element, index) => {
                if (element.collection_name.toLowerCase().match(event.target.value.toLowerCase())) {
                    searchResultsList.push(element);
                }
                if (element.product_list.product_list.length > 0) {
                    element.product_list.product_list.forEach(products => {
                        if (products.product_name.toLowerCase().match(event.target.value.toLowerCase())) {
                            if (products.serial.length > 0) {
                                products.serial.forEach(serial => {
                                    serial['product_name'] = products.product_name;
                                    searchResultsList.push(serial);
                                });
                            }
                        }
                    });
                }
            })
        } else {
            setSearchVisibity(false);
        }
        if (searchResultsList.length > 0) {
            var searchListByCollection = [];
            var searchListByProduct = [];
            searchResultsList.forEach(element => {
                if ('collection_name' in element) {
                    searchListByCollection.push(element);
                }
                if ('product_name' in element) {
                    searchListByProduct.push(element);
                }
            });
            setSearchResultsByCollection(searchListByCollection)
            setSearchResultsByProduct(searchListByProduct)
        }
        searchResultsList.length > 0 ? setSearchVisibity(true) : setSearchVisibity(false);
    }

    useEffect(() => {
        setSearchValue('');
    }, [location])

    return (
        <>
            <header className="header__main header__wrap">

                <div className="mobile_search_wrapper">
                    <div className="header__search__block__inner">
                        <div className="hs__1">
                            <input type="text" className="header__search__field" placeholder="Search Web3" />
                        </div>
                        <div className="hs__2">
                            <button className="header__search__btn close_header_search" onClick={closeHeaderSearch} ><i className="fa-solid fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-md navbar-white">
                    <div className="header__nav__left__wrap">
                        <a className="navbar-brand" href="/home"><img src="/assets/img/logo-light.png" width="100" height="116"
                            className="trendsy-logo" /></a>
                        <a className="mobile__header__search" onClick={openHeaderSearch} href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
                                strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="search__icon" role="img"
                                viewBox="0 0 24 24" focusable="false">
                                <title>Search</title>
                                <circle cx="10.5" cy="10.5" r="7.5" />
                                <path d="M21 21l-5.2-5.2" />
                            </svg>
                        </a>
                        <div className="header__search__wrap">
                            <form className="h__f__glb header__form">
                                <div className="header__search__block">
                                    <div className="header__search__block__inner">
                                        <div className="hs__1">
                                            <Tippy
                                                content={(
                                                    <ul className="list-group" style={{ 'width': '330px', 'height': '250px', 'overflowY': 'scroll' }}>
                                                        {searchResultsByCollection.length > 0 && (
                                                            <>
                                                                <li className="list-group-item bg-transparent ist-group-item-action"><b>Collections</b></li>
                                                                {searchResultsByCollection.map((item, index) => {
                                                                    return (
                                                                        <Link to={'/collection/' + btoa(item.id)} style={{ 'textDecorationLine': 'none', 'color': '#B1B1B1' }} key={'collection' + index}>
                                                                            <li onClick={() => { setSearchVisibity(false) }} className="list-group-item bg-transparent ist-group-item-action">{item.collection_name}</li>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                        {searchResultsByProduct.length > 0 && (
                                                            <>
                                                                <li className="list-group-item bg-transparent ist-group-item-action"><b>Nfts</b></li>
                                                                {searchResultsByProduct.map((item, index) => {
                                                                    return (
                                                                        <Link style={{ 'textDecorationLine': 'none', 'color': '#B1B1B1' }} to={'/product/' + btoa(item.uuid)} key={'product' + index}>
                                                                            <li onClick={() => { setSearchVisibity(false) }} className="list-group-item bg-transparent ist-group-item-action">{item.product_name}</li>
                                                                        </Link>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                        <Link style={{ 'color': 'white', 'textDecorationLine': 'none' }} to={'/all-search-results/' + btoa(searchValue)}><li onClick={() => { setSearchVisibity(false) }} className="list-group-item bg-transparent ist-group-item-action">Show All Items</li></Link>
                                                    </ul>
                                                )}
                                                interactive={true}
                                                appendTo={() => document.body}
                                                placement='bottom'
                                                arrow={false}
                                                role='tooltip'
                                                className='mt-3'
                                                visible={SearchVisibity}
                                                onClickOutside={() => { setSearchVisibity(false) }}
                                            >
                                                <input type="text" autoComplete='' onChange={searchBoxChange} className="header__search__field" id="searchbar" placeholder="Search Web3" value={searchValue} />
                                            </Tippy>
                                        </div>
                                        <div className="hs__2">
                                            <button className="header__search__btn"><i className="fa-solid fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul className="navbar-nav primary__menu">
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#">Explore</a>
                                <ul className="submenu">
                                    <li><Link to={'/all-search-results/' + btoa('allCollectionExplore')}>Collections</Link></li>
                                    <li><Link to={'/all-search-results/' + btoa('allNFTExplore')}>NFTs</Link></li>

                                </ul>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#"  data-toggle="modal" data-target="#myModalspot">Precious Metal Spot Price</a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="navbar-collapse navbar__right">
                        <div className="d-flex justify-content-end align-items-center">

                            {location.pathname != '/connect-wallet' && (<a href="/connect-wallet" className="btn btn-light btn-lg wallet__btn"> {(walletAddress != '' && walletAddress != null && walletAddress != "null") ? truncateAddress(walletAddress) : 'Connect Wallet'}</a>
                            )}
                            <a className="toggle-menu" onClick={toggleMenu} href="javascript:;">
                                <span className="toggle-burger">
                                    <span className="icon-bar icon-bar-top"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </nav >
            </header >
            <LoadingSpinner />
            <div className="panel__menu__container">
                <div className="panel__menu__overlay"></div>
                <div id="sliding__menu">
                    <div className="menu__vertical__align">
                        <div className="menu__align__middle">
                            <div className="panel__sec__menu">
                                <ul className="nav flex-column flex-nowrap overflow-hidden accordion" id="accordion">
                                    <li className="nav-item">
                                        <a className="nav-link text-truncate" href="/home"><span className="d-none d-sm-inline">Home</span></a>
                                    </li>
                                    <li className="nav-item"><Link to={'/all-search-results/' + btoa('allCollectionExplore')}>Collections</Link></li>
                                    <li className="nav-item"><Link to={'/all-search-results/' + btoa('allNFTExplore')}>NFTs</Link></li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" /* onClick={() => { closeModal() }} */ data-toggle="modal" data-target="#myModalspot">Precious Metal Spot Price</a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            <div className="modal fade" id="myModalspot" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-zoom">
                    <div className="modal-content black">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Precious Metal Spot Price</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body scroll-view2" style={{ paddingTop: 0 }}>
                            <div className="col-md-12 ">


                                <table className="table mb-0">

                                    <tr>
                                        <th>Metal</th>
                                        <th>Price(USD/gram)</th>
                                        <th>Chart(last 7 days price)</th>
                                    </tr>
                                    {metal_list.length > 0 && metal_list.map((item, i) => {

                                        if (item.is_displayed != null && item.is_displayed != 0 && item.is_displayed != '') {
                                            return (
                                                <tr key={'preciousMetals' + i}>
                                                    <td>{item.metal}</td>
                                                    <td>{item.price}</td>
                                                    <td style={{ height: 'auto', width: '180px' }}><Line options={options} data={item.data} /></td>
                                                </tr>
                                            )
                                        }
                                    })}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
