import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { updateBuyingStatus, claimingRequest, getMetalList } from '../services/CollectionService';
import { useWeb3React } from "@web3-react/core";
import { connectors, truncateAddress } from "../pages/wallet/connectors";
import WalletConnectProvider from "@walletconnect/web3-provider";
import swal from 'sweetalert';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


import TRENDSY from '../contracts/Trendsy.json';
import {
    TRENDSYMARKET_ADDRESS, PRIVATE_KEY, RPCPROVIDER
} from '../config.js';

const UserDetails = (props) => {

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                }
            }
        },
    };

    const labels = ['', '', '', '', '', '', ''];

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                data: [10, 20, 30, 40, 5, 90, 150],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',

            }
        ],
    };


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [address, setAddress] = useState('');

    const [metal_list, setMetalList] = useState('');







    const [walletAddress, setwalletAddress] = useState([]);
    const [collection_id, setCollection_id] = useState([]);


    const submitValue = () => {
        let data = {
            'name': name,
            'email': email,
            'mobile': number,
            'country': country,
            'state': state,
            'zip': zip,
            'address': address
        };
        data = JSON.stringify(data);
        var wallet_address = window.localStorage.getItem("WALLETADDRESS");
        var collectionID = window.localStorage.getItem("COLLECTIONID");
        claimNow(wallet_address, data, collectionID);

    }


    const claimNow = (wallet_address, user_info, collectionID) => {
        console.log(wallet_address);
        console.log(user_info);

        // $(".loaderp").toggleClass("display");
        const data = { "collection_id": collectionID, name: name, email: email, wallet_address: wallet_address, user_info: user_info };
        console.log(data);

        claimingRequest(data).then((response) => {
            alert(response.message);
            $(".loaderp").toggleClass("display");
            window.location.reload();
        })
    }
    const closeModal = (btn, collection_id) => {
        $('#myModal').toggleClass('display');
    };
    const btnAction = (btn, collection_id) => {
        setCollection_id(collection_id);
        if (btn == 1) {
            buyCollection(collection_id);
        } if (btn == 2) {

            let claim_wallet = props.serial.claim_wallet;

            const web3Modal = new Web3Modal();
            web3Modal.connect().then((connection) => {
                let sender = connection.selectedAddress;
                if (claim_wallet == sender) {
                    window.localStorage.setItem("WALLETADDRESS", sender);
                    window.localStorage.setItem("COLLECTIONID", collection_id);
                    setwalletAddress(sender);
                    $('#myModal').toggleClass('display');
                } else {
                    alert("Wallet not matched.");
                }
            });
        }
    };
    /*  const buyCollection = (collection_id) => {
        const web3Modal = new Web3Modal();
        $(".loaderp").toggleClass("display");
        web3Modal.connect().then((connection)=>{
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
                   let sender=connection.selectedAddress;
                   signer.sendTransaction({
                    to: TRENDSYMARKET_ADDRESS,
                    value: ethers.utils.parseEther("0.0005"),
            }).then((tx)=>{
                console.log(tx); 
                tx.wait().then((receipt)=>{
                    $(".loaderp").toggleClass("display");
                    buyingRequest(sender);
                });
            });    
               });
    
      } */
    const buyCollection = (collection_id) => {
        if (window.localStorage.getItem('provider') == 'metamask' || window.localStorage.getItem('provider') == 'coinbaseWallet' && (window.localStorage.getItem('WALLETADDRESS') !== null && window.localStorage.getItem('WALLETADDRESS') !== 'null')) {
            handleBuyNowMetamaskAndCoinbase();
        }
        else if (window.localStorage.getItem('provider') == 'walletConnect' && window.localStorage.getItem('WALLETADDRESS') !== null && window.localStorage.getItem('WALLETADDRESS') !== 'null') {
            handleBuyNowWC();
        }
        else if (window.localStorage.getItem('provider') == 'null' || window.localStorage.getItem('provider') == null || window.localStorage.getItem('WALLETADDRESS') == null || window.localStorage.getItem('WALLETADDRESS') == 'null') {
            swal('Error', 'Please connect wallets in order to complete transaction', 'error');
        }

    }

    const handleBuyNowMetamaskAndCoinbase = async () => {
        $(".loaderp").toggleClass("display");
        let provider = '';
        if (window.localStorage.getItem('provider') == 'metamask') {
            try {
                provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
            } catch (error) {
                provider = window.ethereum;
            }
        }

        else
            provider = window.ethereum;
        provider.enable();
        // Get the user's selected address
        const accounts = await provider.request({ method: 'eth_accounts' });
        const userAddress = accounts[0];
        // startWalletConnectSign(provider, userAddress);
        sendTransaction(provider, userAddress);
    }

    /* start WC wallet connect */
    const handleBuyNowWC = async () => {
        $(".loaderp").toggleClass("display");
        const provider = new WalletConnectProvider({
            infuraId: `${process.env.REACT_APP_INFURA_ID_FOR_WALLET_CONNECT}`,
        });
        provider.enable();
        // Get the user's selected address
        const accounts = await provider.request({ method: 'eth_accounts' });
        const userAddress = accounts[0];
        // startWalletConnectSign(provider, userAddress);
        sendTransaction(provider, userAddress);
    }

    /* starting sign */
    const startWalletConnectSign = async (provider, userAddress) => {
        const message = 'Hello, Marble wallet!';
        const signature = await provider.request({
            method: 'personal_sign',
            params: [message, userAddress, ''], // message, address, passphrase
        });

        console.log('Signature:', signature);
    }

    /* start transaction */
    const sendTransaction = async (provider, userAddress) => {
        if (typeof props.total_price != 'undefined' && props.total_price != '' && props.total_price != 'null' && props.total_price != null) {
            // Transaction object
            let transactionAmount = '';
            if (window.localStorage.getItem('provider') == 'metamask') {
                transactionAmount = Number(ethers.utils.parseEther(props.total_price.toFixed(6))).toString(16);
            } else {
                transactionAmount = ethers.utils.parseEther(props.total_price.toFixed(6)).toString(); // (in wei)
            }
            const transaction = {
                from: userAddress,
                to: TRENDSYMARKET_ADDRESS,
                value: transactionAmount,
                /*  gas: '200000',
                 gasPrice: '50000000000',  */// 50 gwei (in wei)
            };

            // Send the transaction
            try {
                const transactionHash = await provider.request({
                    method: 'eth_sendTransaction',
                    params: [transaction],
                });
                $(".loaderp").toggleClass("display");
                buyingRequest(userAddress); // i think it is buying request
                // console.log('Transaction Hash:', transactionHash);
            } catch (error) {
                $(".loaderp").toggleClass("display");
                swal("Error!", error.message, "error");
            }
        } else {
            swal("Error!", 'Ether cannot be empty', "error");
        }

    }

    const buyingRequest = (wallet_address) => {


        let contact_address = props.buyingList[0].contract_id;
        let NFT_tokens = [];
        props.buyingList.forEach(function (serial, index) {
            NFT_tokens.push(serial.nft_count_id);
        });
        $(".loaderp").toggleClass("display");
        const provider = new ethers.providers.JsonRpcProvider(RPCPROVIDER);
        const wallet = new ethers.Wallet(PRIVATE_KEY, provider);

        let contract = new ethers.Contract(contact_address, TRENDSY.abi, wallet);

        contract.connect(wallet).bulkTransfer(TRENDSYMARKET_ADDRESS, wallet_address, NFT_tokens).then((transaction) => {
            transaction.wait().then((receipt) => {
                const eventFilter = contract.filters.bulkTransferCompleted();

                console.log(eventFilter);
                console.log(receipt.blockHash);
                contract.queryFilter(eventFilter, receipt.blockHash).then((events) => {
                    console.log(events);
                    const tokenIdListBigNumber = events[0].args[0];
                    let arr = [];
                    tokenIdListBigNumber.forEach(function (tokenId, index) {
                        tokenId = tokenId.toNumber();
                        arr.push(tokenId);
                    });


                    console.log(arr);
                    $(".loaderp").toggleClass("display");
                    update_buying_status(arr, wallet_address, contact_address);

                });
            });
        });





    }

    const update_buying_status = (tokens_arr, wallet_address, contact_address) => {
        $(".loaderp").toggleClass("display");
        const data = { "tokens_arr": tokens_arr, wallet_address: wallet_address, contact_address: contact_address };
        console.log(data);
        updateBuyingStatus(data).then((response) => {

            console.log('response.status: ', response.status); // 👉️ 200
            alert(response.message);
            $(".loaderp").toggleClass("display");
            window.location.reload();
        })
    }
    const copyLink = (e) => {
        $('.copy-link-sucess').fadeIn();
        var value = e.currentTarget.getAttribute('data-link');
        var input_temp = document.createElement("input");
        input_temp.value = value;
        document.body.appendChild(input_temp);
        input_temp.select();
        document.execCommand("copy");
        document.body.removeChild(input_temp);
        setTimeout(() => {
            $('.copy-link-sucess').fadeOut();
        }, 2000);
    }

    useEffect(() => {
        getMetalList().then((response) => {

            response.data.trndMetalPrice.forEach(function (metal, index) {
                let price = metal.price;
                let labels = ['', '', '', '', '', '', ''];
                let data = {
                    labels,
                    datasets: [
                        {
                            fill: true,
                            data: metal.data,
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',

                        }
                    ],
                };

                response.data.trndMetalPrice[index]['data'] = data;

            });
            setMetalList(response.data.trndMetalPrice)
        });


    }, [])

    return (
        <div>
            <section className="user_details_section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 ">
                            <div className="user_heading_block" >
                                {props.logo && (
                                    <img className="logo-banner" src={props.logo} alt="" />
                                )}
                            </div>
                            <div style={{ marginLeft: '7px' }}>
                                <p className="social_share_heading">Share link to this page</p>
                                <div className="social_icon">
                                    <a target={'blank'} href={'https://twitter.com/intent/tweet?text=' + encodeURI(props.site_link)}><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a target={'blank'} href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(props.site_link)}><i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                                    <a target={'blank'} href={'https://telegram.me/share/url?url=' + encodeURI(props.site_link) + '&text='}><i className="fa fa-telegram" aria-hidden="true"></i></a>
                                    <a target={'blank'} href={"mailto:?body=" + encodeURI(props.site_link)} ><i className="fa fa-envelope" aria-hidden="true"></i></a>
                                    <a href="javascript:;" onClick={copyLink} data-link={encodeURI(props.site_link)} ><i className="fa fa-link" aria-hidden="true"></i></a>
                                </div>
                                <p className="text-success copy-link-sucess" style={{ 'display': 'none' }}>Link Copied !</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="user_heading_block">
                                <h1 className="user_heading">{(props.name != '' && props.name != null) ? props.name : ''}</h1>
                                {props.needCreatedBy && (
                                    <p className="createdby">Created by <Link to={props.brand_link}>{props.createdby}</Link></p>
                                )}

                                {(props.description != '' && props.description != null) && <p className="user_details" dangerouslySetInnerHTML={{ __html: props.description }}></p>}
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="user_address_block">
                                {/* {props.needTopAddress && (
                                <div className="address_top_section">
                                    <p className="address_top_section_row">Floor <span>3 ETH</span></p>
                                    <p className="address_top_section_row">Volume <span>122.2K ETH</span></p>
                                    <p className="address_top_section_row">Items <span>10K</span></p>
                                    <p className="address_top_section_row">Owners <span>5.7K</span></p>
                                </div>
                            )} */}
                                {props.needDownAddress && (
                                    <>
                                        <p className="address_top_section_row">Address</p>
                                        <p className="address_top_section_row">{(props.smart_contact != '' && props.smart_contact != null) ? props.smart_contact : ''}</p>
                                        {props.total_price_buy_btn && (
                                            <>
                                                {props.total_price != '' && props.total_price != null && (
                                                    <>
                                                        <div className="price_block top10">
                                                            <div className="price_setion">
                                                                <p className="price_small_txt">Total Price</p>
                                                                <p className="price_value">{props.total_price.toFixed(6)}(ETH) <div> ({props.total_price_usd.toFixed(2)} USD)</div></p>
                                                                <p className="price_details_txt"><span>By</span><a href="#">Trendsy</a></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="price_btn">
                                                    {props.minting_type == "mint_nft_brand_metal" && (
                                                        <button className="common_full_button" onClick={() => { btnAction(props.buyBtn, props.collection_id) }} >{props.set_btn_text}</button>

                                                    )}




                                                </div>
                                            </>
                                        )}

                                    </>
                                )}
                            </div>
                            {props.minting_type == "mint_nft_brand_metal" && (
                                <div className=" scroll-view2">
                                    <div className=" row col-md-12 ">


                                        <table className="table mb-0 white-color scroll-table" >

                                            <tr style={{ border: "1px solid rgba(var(--color-black), 0.08)", fontSize: '15px' }}>
                                                <th>Metal</th>
                                                <th>Price (USD/gram)</th>
                                                <th>Chart (last 7 days price)</th>
                                            </tr>
                                            {metal_list.length > 0 && metal_list.map((item, i) => {

                                                if (item.is_displayed != null && item.is_displayed != 0 && item.is_displayed != '') {
                                                    return (
                                                        <tr key={'preciousMetals' + i}>
                                                            <td>{item.metal}</td>
                                                            <td>{parseFloat(item.price).toFixed(4)}</td>
                                                            <td style={{ height: 'auto', width: '180px', maxWidth: '180px' }}><Line options={options} data={item.data} /></td>
                                                        </tr>
                                                    )
                                                }
                                            })}

                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>



            </section >
            <div id="myModal" className="modal" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog ">
                    <div className="modal-content black" >
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">
                                Address Info
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => { closeModal() }} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">

                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Add Your Address Info</h5>
                                            </div>

                                            <div >



                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">Name</label>
                                                            <input type="text" className="form-control" placeholder=" Name" onChange={e => setName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">Email</label>
                                                            <input type="text" className="form-control" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input"> Phone Number</label>
                                                            <input type="text" className="form-control" placeholder=" Phone Number" onChange={e => setNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">Country</label>
                                                            <select onChange={e => setCountry(e.target.value)} className='form-control' name='member_country' >
                                                                <option value="">Please Select</option>
                                                                <option value="USA">USA</option>
                                                                <option value="Canada">CANADA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">State</label>
                                                            <input type="text" className="form-control" placeholder="State" onChange={e => setState(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">Zip Code</label>
                                                            <input type="text" className="form-control" placeholder=" Zip" onChange={e => setZip(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="  form-label  form-label-required" htmlFor="formrow-firstname-input">Address</label>
                                                            <input type="text" className="form-control" placeholder=" Address" onChange={e => setAddress(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="mb-3 top20">
                                                    <h3></h3>
                                                    <h3></h3>
                                                    <button
                                                        className="btn btn-success w-100 waves-effect waves-light"
                                                        onClick={() => { submitValue() }} >Submit</button>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
}
/* decalring default props */
UserDetails.defaultProps = {
    needTopAddress: false,
    needDownAddress: false,
    name: "",
    description: "",
    smart_contact: "",
    needCreatedBy: false,
    createdby: "",
    brand_link: "#",
    total_price_buy_btn: false,
    set_btn_text: '',
    total_price: '',
    buyBtn: '',
}

export default UserDetails
