import React from 'react'

const BannerAndLogo = (props) => {
    return (
        <section className="banner_section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner_inner_sec">
                            <div className="banner_img_ar">
                                {props.banner && (
                                    <img src={props.banner} alt="" />
                                )}
                            </div>
                            {/* <div className="banner_thumb">
                                {props.logo && (
                                    <img src={props.logo} alt="" />
                                )}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerAndLogo
