let url_base=`${process.env.REACT_APP_API_URL}`;
var host = window.location.host;
if(host!="http://54.147.228.241"){
 url_base=`${process.env.REACT_APP_API_URL_1}`;
}


let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export async function getPaymentAccessToken(data) {

  const response = await fetch(url_base+`api/getAccessTokenByRefreshToken`, {
        method: 'GET',
        headers: headers,
      })
    return await response.json();
}


export async function sendPayment(data,accessToken) {

  let request_id=Date.now();
  headers.append('Authorization', 'Bearer '+accessToken);
  headers.append('request-id', request_id);
  const response = await fetch('https://sandbox.api.intuit.com/quickbooks/v4/payments/charges', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
    })
  return await response.json();
}

export async function getRequestDetails(id) {

  const response = await fetch(url_base+`api/claiming-details-by-id`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({claim_id: id })
    })
  return await response.json();
}

export async function updateShippingPaymentStatus(id) {

  
  
  const response = await fetch(url_base+`api/updateShippingPaymentStatus`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({claim_id: id })
    })
  return await response.json();
}
