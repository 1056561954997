import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import BannerAndLogo from '../../components/BannerAndLogo';
import UserDetails from '../../components/UserDetails';
import { getCollectionDetails, getMetalList, getSmartContactId } from '../../services/CollectionService';
import { getQueryData } from '../../helper/Helper';
import $ from 'jquery'
import { getBrandDetails, getProductOfEachCollection } from '../../services/BrandService';
import { useLocation } from 'react-router';


const Collection = (props) => {
    const [brand, setBrand] = useState([]);
    const [collection, setCollection] = useState([]);
    const [productList, setProductList] = useState([]);
    const [smartContact, setSmartContact] = useState([]);
    const [buyingList, setBuyingList] = useState([]);
    const [claimRequest, setClaimRequest] = useState([]);
    const [btnText, setBtnText] = useState([]);
    const [buyBtn, setBuyBtn] = useState([]);
    const [collectionList, setCollectionList] = useState([]);
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [type, setType] = useState('');
    const [search, setSearch] = useState('');
    const [siteLink, setsiteLink] = useState('');
    const [collectionMetals, setCollectionMetals] = useState([]);
    const [metalList, setMetalList] = useState([]);
    const [serialWallet, setSerialWallet] = useState([]);
    document.title = "Collections"
    const location = useLocation();


    useEffect(() => {

        $(".open_filter").on('click', function (e) {
            e.preventDefault();
            $("body").addClass("reveal-filter");
        });

        $(".close_filter").on('click', function (e) {
            e.preventDefault();
            $("body").removeClass("reveal-filter");
        });
        let data = {
            collection_id: getQueryData(),
            minting_status: 1
        }
        getCollectionDetails(data).then((response) => {
            if (response.code == 200) {
                let buying_list = [];
                setBrand(response.data.brand);
                setCollection(response.data.collection);
                setProductList(response.data.product_list);
                setClaimRequest(response.data.claimRequests);
                /* geting metal information */
                let metalsInThisProduct = [];
                if (response.data.product_list.length > 0) {
                    let product_temp = (response.data.product_list[0]['serial'])[0]['trndProductProperties'];
                    if (product_temp.length > 0) {
                        product_temp.forEach(metal => {
                            metalsInThisProduct.push(metal.key.slice(0, metal.key.indexOf('(', 0)).trim());
                        });
                        setCollectionMetals(metalsInThisProduct);
                    }
                }

                /* preparing buying list */
                response.data.product_list.forEach(function (product, index) {
                    product.serial.forEach(function (serial, index) {
                        if (serial.buying_status == 0) {
                            buying_list.push(serial);
                        }
                        setSerialWallet(serial);
                    });
                });
                setBuyingList(buying_list);

                /* setting button text */
                if (buying_list.length > 0 && buying_list.length == 10 && response.data.claimRequests.length == 0) {
                    setBtnText('Buy This Full Collection');
                    setBuyBtn(1);
                }
                if (buying_list.length > 0 && buying_list.length < 10 && response.data.claimRequests.length == 0) {
                    setBtnText('Buy ' + buying_list.length + " Quarks From This Collection");
                    setBuyBtn(1);
                }
                if (buying_list.length == 0 && response.data.claimRequests.length == 0) {
                    setBtnText('Claim This Full Collection')
                    setBuyBtn(2);
                }
                if (response.data.claimRequests.length > 0) {
                    setBtnText('This Collection already Claimed')
                    setBuyBtn(3);
                }


                /* getting smart contact */
                let data = {
                    brand_id: response.data.brand.id,
                    collection_id: response.data.collection.id,
                }

                /* smart contact id fetching*/
                getSmartContactId(data).then((response) => {
                    if (response.code == 200) {
                        setSmartContact(response.data.contract_id)
                    }
                })

                /* 
                    * getting all collections from this brand
                    * too much api call, cause laravel api is not configured properly
                 */
                getBrandDetails(response.data.brand.id).then((brand_details) => {
                    if (brand_details.code == 200) {
                        let collection_id_array = [];
                        brand_details.data.collection_list.forEach(element => {
                            collection_id_array.push(element.id);
                        });
                        getProductOfEachCollection(collection_id_array).then((collections) => {
                            if (collections.code == 200) {
                                setCollectionList(collections.data);
                            }
                        })
                    }
                });

                /* get all metals */
                getMetalList({}).then((response) => {
                    setMetalList(response.data.trndMetalPrice);
                })
            }
        })
        props.onChangeCta(false)
        setMax(''); setMin(''); setType(''); setSearch('');
        $('.small_bubble_ar .small_bubble').removeClass('small_bubble_hover');
        setsiteLink(window.location.href)
        window.scrollTo(0, 0); // scroll to top
    }, [location]);

    useEffect(() => {
        $('.accordion-list > li > h3').next(".answer").hide();
        $('.small_bubble_ar .small_bubble').removeClass('small_bubble_hover');
    }, []);

    /* collection price */
    const collection_price = (collection_product_list) => {
        let buying_list = [];
        collection_product_list.forEach(function (product, index) {
            product.serial.forEach(function (serial, index) {
                if (serial.buying_status == 0) {
                    buying_list.push(serial);
                }
            });
        });
        return buying_list;
    }

    /* tab changer */
    const tabChanger = (event) => {
        let element = event.currentTarget;
        let tab_id = element.getAttribute('data-tab');
        $('ul.product_tab li a').removeClass('current');
        $('.prdBlock').removeClass('current');
        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    }

    /* toggle dropdown */
    const toggleDropdown = (event) => {
        let element = event.currentTarget;
        if ($(element).hasClass("active")) {
            $(element).removeClass("active").next(".answer").slideUp();
        } else {
            $(".accordion-list > li > h3.active").next(".answer").slideUp();
            $(".accordion-list > li > h3.active").removeClass("active");
            $(element).addClass("active").next(".answer").slideDown();
        }
        return false;
    }

    /* handle value change */
    const handleChange = (event) => {
        let element = event.currentTarget;
        switch (element.name) {
            case 'min': {
                setMin(element.value)
            }
                break;
            case 'max': {
                setMax(element.value)
            }
                break;

            default:
                break;
        }
    }

    /* handle type change */
    const handleType = (event) => {
        setType(event.target.name);
        $('.small_bubble_ar .small_bubble').removeClass('small_bubble_hover');
        event.target.classList.add('small_bubble_hover');
    }

    return (
        <>
            <BannerAndLogo
                banner={collection.collection_banner_image}
                logo={collection.collection_image}
            />
            {/* user details */}
            <UserDetails
                name={collection.collection_name}
                description={collection.collection_description}
                smart_contact={smartContact}
                minting_type={collection.minting_type}
                needDownAddress={true}
                logo={collection.collection_image}
                needTopAddress={true}
                needCreatedBy={true}
                createdby={brand.brand_name}
                brand_link={'/brand/' + btoa(brand.id)}
                total_price_buy_btn={true}
                collection_id={getQueryData()}
                set_btn_text={btnText}
                buyBtn={buyBtn}
                buyingList={buyingList}
                serial={serialWallet}
                site_link={siteLink}
                total_price_usd={buyingList.length > 0 ? buyingList.length * buyingList[0].price : ''}
                total_price={buyingList.length > 0 ? buyingList.length * buyingList[0].eth_price : ''}
            />

            {/* product section */}
            <section className="product_section">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="product_nav_section">
                                <ul className="product_tab">
                                    <li><a href="javascript:;" onClick={tabChanger} data-tab="prdTab-1" className="current">Items</a></li>
                                    <li><a href="javascript:;" onClick={tabChanger} data-tab="prdTab-2" className="" style={{ display: 'none' }}>Activity</a></li>
                                </ul>
                            </div>
                            <div className="product_conty_section">
                                <div className="prdBlock current" id="prdTab-1">
                                    <div className="product_top_block">
                                        <div className="refresh_button mr-3 open_filter">
                                            <button><i className="fa fa-filter" aria-hidden="true"></i></button>
                                        </div>
                                        <div className="custom_select_box">
                                            <div className="dropdown">
                                                <button className="btn btn_drop_custm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                                    <span>Recently listed</span>
                                                    <i className="fa fa-caret-down"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li><a className="dropdown-item" href="#">Australia</a></li>
                                                    <li><a className="dropdown-item" href="#">India</a></li>
                                                    <li><a className="dropdown-item" href="#">United States</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="refresh_button">
                                            <button><i className="fa fa-refresh" aria-hidden="true"></i></button>
                                        </div>
                                        <div className="search_bar">
                                            <input type="text" placeholder="Search by NFTs" onChange={(e) => { setSearch(e.target.value.toLowerCase()) }} value={search} />
                                            <span><a href="#"><i className="fa-solid fa-search"></i></a></span>
                                        </div>
                                    </div>
                                    <div className="product_display_section">
                                        <div className="row">
                                            {/* sidebar */}
                                            <div className="col-md-3">

                                                <div className="filter_area panel__filter__container">
                                                    <p className="filter_heading">Filter</p>
                                                    <a href="#" className="close_filter">X</a>
                                                    <ul className="accordion-list">
                                                        {/* <li>
                                                            <h3 onClick={toggleDropdown} >Status</h3>
                                                            <div className="answer">
                                                                <div className="small_bubble_ar">
                                                                    <button className="small_bubble">All</button>
                                                                    <button className="small_bubble">Buy Now</button>
                                                                    <button className="small_bubble">Open for Offers</button>
                                                                </div>
                                                            </div>
                                                        </li> */}
                                                        <li>
                                                            <h3 onClick={toggleDropdown} >Price</h3>
                                                            <div className="answer">
                                                                <div className="price_filter">
                                                                    <span><input type="number" placeholder="min" value={min} onChange={handleChange} name='min' /></span>
                                                                    <span>to</span>
                                                                    <span><input type="number" placeholder="max" value={max} onChange={handleChange} name='max' /></span>
                                                                </div>
                                                                {/* <div className="price_block_btn">
                                                                    <button className="common_full_button">Apply</button>
                                                                </div> */}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <h3 onClick={toggleDropdown} >Type</h3>
                                                            <div className="answer">
                                                                <div className="small_bubble_ar">
                                                                    <button className="small_bubble" name='all' onClick={handleType} >All</button>
                                                                    <button className="small_bubble" name='buy_now' onClick={handleType}>Buy Now</button>
                                                                    <button className="small_bubble" name='already_purchased' onClick={handleType}>Already Purchased</button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <h3 onClick={toggleDropdown} >Collections</h3>
                                                            <div className="answer">
                                                                <div className="collection_block">
                                                                    {collectionList.length > 0 && collectionList.map((collections, i) => {
                                                                        return (
                                                                            <div className="collection_block_row" style={{ "textDecoration": "none" }} key={i}>
                                                                                <div className="coll_thumb"><img src={collections.collection.collection_image} alt="" /></div>
                                                                                <div className="coll_details">
                                                                                    <Link to={`/collection/` + btoa(collections.collection.id)} key={i} style={{ "textDecoration": "none" }} >
                                                                                        <div className="coll_details_top">
                                                                                            <div className="coll_details_top_l">{collections.collection.collection_name}</div>
                                                                                            <div className="coll_details_top_r">{collection_price(collections.product_list).length > 0 ? (collection_price(collections.product_list).length * collection_price(collections.product_list)[0].eth_price).toFixed(4) + '(ETH)' : ''}    <div className="">{collection_price(collections.product_list).length > 0 ? (collection_price(collections.product_list).length * collection_price(collections.product_list)[0].price).toFixed(2) + '(USD)' : ''} </div></div>
                                                                                        
                                                                                         
                                                                                        </div>
                                                                                    </Link>
                                                                                    <div className="coll_details_bot">
                                                                                       
                                                                                        {/* <div className="coll_details_bot_r">Volume</div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {collection.minting_type == "mint_nft_brand_metal" && (
                                                            <li>
                                                                <h3 onClick={toggleDropdown} >Precious Metals</h3>
                                                                <div className="answer">
                                                                    <div className="collection_block">
                                                                        {collectionMetals.length > 0 && metalList.length > 0 && (
                                                                            metalList.map((metal, i) => {
                                                                                return (
                                                                                    collectionMetals.includes(metal.metal) &&
                                                                                    (
                                                                                        <div className="collection_block_row" style={{ "textDecoration": "none" }} key={i}>
                                                                                            <div className="coll_details">
                                                                                                <div className="coll_details_top">
                                                                                                    <div className="coll_details_top_l">{metal.metal}</div>
                                                                                                    <div className="coll_details_top_r">{parseFloat(metal.price).toFixed(6)} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )
                                                                            })
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* sidebar end */}
                                            <div className="col-md-9">
                                                <div className="product_display_thumb_ar">
                                                    <div className="row">
                                                        {productList.length > 0 && productList.map((product, i) => {
                                                            return (
                                                                product.serial.map((item, j) => {
                                                                    if (min != '' && max != '' && item.eth_price >= min && item.eth_price <= max) {
                                                                        if (type == 'already_purchased') {
                                                                            if (item.buying_status == 1 && (item.claim != '' || item.claim != null)) {
                                                                                if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } if (search == '') {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>


                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        }
                                                                        if (type == 'buy_now') {
                                                                            if (item.buying_status != 1 && item.claim == '' || item.claim == null) {
                                                                                if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } if (search == '') {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">




                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        }
                                                                        if (type == 'all' || type == '') {
                                                                            if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                return (
                                                                                    <div className="col-md-4 product_display_blk" key={j}>
                                                                                        <div className="product_display_thumb">
                                                                                            <div className="product_display_image_thumb">
                                                                                                <img src={item.product_image} alt="" />
                                                                                            </div>
                                                                                            <div className="product_display_details">
                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Product Name</span>
                                                                                                    <span>{product.product_name}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Price</span>
                                                                                                    <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="product_display_details ">

                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Serial Number</span>
                                                                                                    <span>{item.serial_no}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Owner</span>
                                                                                                    <span>{item.claim} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="product_display_button">

                                                                                                {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } if (search == '') {
                                                                                return (
                                                                                    <div className="col-md-4 product_display_blk" key={j}>
                                                                                        <div className="product_display_thumb">
                                                                                            <div className="product_display_image_thumb">
                                                                                                <img src={item.product_image} alt="" />
                                                                                            </div>
                                                                                            <div className="product_display_details">
                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Product Name</span>
                                                                                                    <span>{product.product_name}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Price</span>
                                                                                                    <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="product_display_details ">

                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Serial Number</span>
                                                                                                    <span>{item.serial_no}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Owner</span>
                                                                                                    <span>{item.claim} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="product_display_button">

                                                                                                {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    } if (min == '' && max == '') {
                                                                        if (type == 'already_purchased') {
                                                                            if (item.buying_status == 1 && (item.claim != '' || item.claim != null)) {
                                                                                if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } if (search == '') {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        }
                                                                        if (type == 'buy_now') {
                                                                            if (item.buying_status != 1 && (item.claim == '' || item.claim == null)) {
                                                                                if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="product_display_details ">

                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Serial Number</span>
                                                                                                        <span>{item.serial_no}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } if (search == '') {
                                                                                    return (
                                                                                        <div className="col-md-4 product_display_blk" key={j}>
                                                                                            <div className="product_display_thumb">
                                                                                                <div className="product_display_image_thumb">
                                                                                                    <img src={item.product_image} alt="" />
                                                                                                </div>
                                                                                                <div className="product_display_details">
                                                                                                    <div className="product_display_details_left">
                                                                                                        <span>Product Name</span>
                                                                                                        <span>{product.product_name}</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Price</span>
                                                                                                        <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                    </div>
                                                                                                    <div className="product_display_details_righr">
                                                                                                        <span>Owner</span>
                                                                                                        <span>{item.claim} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="product_display_button">

                                                                                                    {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                    {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        }
                                                                        if (type == 'all' || type == '') {
                                                                            if (search != '' && product.product_name.toLowerCase().match(search)) {
                                                                                return (
                                                                                    <div className="col-md-4 product_display_blk" key={j}>
                                                                                        <div className="product_display_thumb">
                                                                                            <div className="product_display_image_thumb">
                                                                                                <img src={item.product_image} alt="" />
                                                                                            </div>
                                                                                            <div className="product_display_details">
                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Product Name</span>
                                                                                                    <span>{product.product_name}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Price</span>
                                                                                                    <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="product_display_details ">

                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Serial Number</span>
                                                                                                    <span>{item.serial_no}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Owner</span>
                                                                                                    <span>{item.claim} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="product_display_button">

                                                                                                {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } if (search == '') {
                                                                                return (
                                                                                    <div className="col-md-4 product_display_blk" key={j}>
                                                                                        <div className="product_display_thumb">
                                                                                            <div className="product_display_image_thumb">
                                                                                                <img src={item.product_image} alt="" />
                                                                                            </div>
                                                                                            <div className="product_display_details">
                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Product Name</span>
                                                                                                    <span>{product.product_name}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Price</span>
                                                                                                    <span>{parseFloat(item.eth_price).toFixed(4)}(ETH)  ({parseFloat(item.price).toFixed(2)} USD)</span>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="product_display_details ">

                                                                                                <div className="product_display_details_left">
                                                                                                    <span>Serial Number</span>
                                                                                                    <span>{item.serial_no}</span>
                                                                                                </div>
                                                                                                <div className="product_display_details_righr">
                                                                                                    <span>Owner</span>
                                                                                                    <span>{item.claim} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="product_display_button">

                                                                                                {collection.minting_type == 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}

                                                                                                {collection.minting_type != 'mint_nft_collector' && (<Link to={'/product/' + btoa(item.uuid)}><button className="common_full_button">{collection.minting_type == 'mint_nft_brand_metal' ? 'View Quark' : 'View Product'}</button></Link>)}




                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="prdBlock" id="prdTab-2">
                                    <div className="activity_block">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="activity_block_row">
                                                    <div className="activity_block_image_thumb">
                                                        <a href="#">
                                                            <img src="/assets/img/Quark-logo.png" alt="" />
                                                        </a>
                                                        <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                    </div>
                                                    <div className="activity_block_conty">
                                                        <p className="activity_heading"><a href="#">Cool Cat #7934</a></p>
                                                        <p className="activity_sub">
                                                            <span className="activity_sub_1">listed by</span>
                                                            <span className="activity_identification">
                                                                <img src="/assets/img/thumb_bg_small.png" alt="" />
                                                            </span>
                                                            <span className="activity_sub_3"><a href="#">0x1f8b5b622...fd00</a></span>
                                                            <span className="activity_sub_4">for</span>
                                                            <span className="activity_sub_5">3.89 ETH</span>
                                                        </p>
                                                        <p className="activity_time">8 minutes ago</p>
                                                    </div>
                                                </div>
                                                <div className="activity_block_row">
                                                    <div className="activity_block_image_thumb">
                                                        <a href="#">
                                                            <img src="/assets/img/Quark-logo.png" alt="" />
                                                        </a>
                                                        <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                    </div>
                                                    <div className="activity_block_conty">
                                                        <p className="activity_heading"><a href="#">Cool Cat #7934</a></p>
                                                        <p className="activity_sub">
                                                            <span className="activity_sub_1">listed by</span>
                                                            <span className="activity_identification">
                                                                <img src="/assets/img/thumb_bg_small.png" alt="" />
                                                            </span>
                                                            <span className="activity_sub_3"><a href="#">0x1f8b5b622...fd00</a></span>
                                                            <span className="activity_sub_4">for</span>
                                                            <span className="activity_sub_5">3.89 ETH</span>
                                                        </p>
                                                        <p className="activity_time">8 minutes ago</p>
                                                    </div>
                                                </div>
                                                <div className="activity_block_row">
                                                    <div className="activity_block_image_thumb">
                                                        <a href="#">
                                                            <img src="/assets/img/Quark-logo.png" alt="" />
                                                        </a>
                                                        <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                    </div>
                                                    <div className="activity_block_conty">
                                                        <p className="activity_heading"><a href="#">Cool Cat #7934</a></p>
                                                        <p className="activity_sub">
                                                            <span className="activity_sub_1">listed by</span>
                                                            <span className="activity_identification">
                                                                <img src="/assets/img/thumb_bg_small.png" alt="" />
                                                            </span>
                                                            <span className="activity_sub_3"><a href="#">0x1f8b5b622...fd00</a></span>
                                                            <span className="activity_sub_4">for</span>
                                                            <span className="activity_sub_5">3.89 ETH</span>
                                                        </p>
                                                        <p className="activity_time">8 minutes ago</p>
                                                    </div>
                                                </div>
                                                <div className="activity_block_row">
                                                    <div className="activity_block_image_thumb">
                                                        <a href="#">
                                                            <img src="/assets/img/Quark-logo.png" alt="" />
                                                        </a>
                                                        <span><a href=""><i className="fa fa-tag" aria-hidden="true"></i></a></span>
                                                    </div>
                                                    <div className="activity_block_conty">
                                                        <p className="activity_heading"><a href="#">Cool Cat #7934</a></p>
                                                        <p className="activity_sub">
                                                            <span className="activity_sub_1">listed by</span>
                                                            <span className="activity_identification">
                                                                <img src="/assets/img/thumb_bg_small.png" alt="" />
                                                            </span>
                                                            <span className="activity_sub_3"><a href="#">0x1f8b5b622...fd00</a></span>
                                                            <span className="activity_sub_4">for</span>
                                                            <span className="activity_sub_5">3.89 ETH</span>
                                                        </p>
                                                        <p className="activity_time">8 minutes ago</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="small_bubble_ar">
                                                    <button className="small_bubble">All</button>
                                                    <button className="small_bubble">Buy Now</button>
                                                    <button className="small_bubble">Open for Offers</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Collection
