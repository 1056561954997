export const nftmarketaddress = "0xe9bc4d394B2b25F6e07fe1811099bE24c348FbEc"
export const nftaddress = "0x2934fA410F1d5Be778Bc5bC9d9951bfBE037AE72"

export const nftmarketaddress2 = "0x9F323333327EF405bFb23bd68Eba6557d6edF915"
export const nftaddress2 = "0x2Ab827A390658cC2224c7cD67a64E396dC0d2cdA"

export const nftmarketaddress3 = "0xd9B1113a0bBE7DC1ABDf07A8788D8f9714b9d86f"
export const nftaddress3 = "0x5577c81D4f0EBe0CdED3C9755f31b34581735e18"

export const nftmarketaddress4 = "0x161f62177319D8e7F2AC7A68b72aa6962dfD7414"
export const nftaddress4 = "0x6f6d2aADD6A26935a95984a12a3163e4072b5559"





export const TRENDSYMARKET_ADDRESS = "0xC25c4F6359485d445A603D345ef8Aff14B86f6ae"
export const PRIVATE_KEY = "ff9d712fc349e2dd3f53793af58e6baad9903083e7ecd4f92c9c24846203263c"
export const RPCPROVIDER = "https://rpc-mumbai.maticvigil.com"

